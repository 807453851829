.progress-green {
  color: #3aba75;
}

.response-list-item-view {
  padding-bottom: 10px;
  position: relative;
  border-left: 2px solid transparent;
  left: -2px;
  transition: border 0.2s ease-out;
}
.response-list-item-view:hover {
  z-index: 1;
}
.response-list-item-view .response-detail {
  margin-left: 50px;
}
.response-list-item-view .response-by-line {
  color: #000;
  margin-right: 5px;
}
.response-list-item-view .response-time {
  color: #828282;
}
.response-list-item-view .response-body {
  margin-bottom: 0px;
  font-size: 1em;
  line-height: 24px;
  overflow: hidden;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.response-list-item-view .response-body img {
  height: auto !important;
}

.response-list-item-view .response-body p:first-child {
  margin-top: 0px;
}
.response-list-item-view .response-body p:last-child {
  margin-bottom: 0px;
}
.response-list-item-view .response-read-more {
  display: none;
  text-align: center;
  position: relative;
  top: 0;
  z-index: 10;
  border-top: 1px solid #dedede;
  background-color: #ebebeb;
}
.response-list-item-view .response-read-more:before {
  content: "";
  display: block;
  height: 25px;
  position: absolute;
  width: 100%;
  top: -25px;
}

.response-list-item-view .response-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.response-list-item-view .response-info .discussion-admin-menu-container {
  flex-grow: 1;
  text-align: right;
}

.response-list-item-view
  .response-info
  .discussion-admin-menu-container
  .discussion-admin-menu {
  position: relative;
}

.response-list-item-view:hover
  .response-info
  .discussion-admin-menu-container
  .discussion-admin-menu {
  display: inline;
}

.response-list-item-view .response-meta a,
.response-list-item-view .response-meta > span {
  color: #828282;
  margin-right: 10px;
}
.response-list-item-view .response-meta a:hover {
  text-decoration: none;
}
.response-list-item-view .response-votes {
  display: inline-flex;
  align-items: center;
}
.response-list-item-view .response-votes i {
  margin-right: 1px;
}
.response-list-item-view .response-votes .vote-prompt {
  display: none;
}
.response-list-item-view .response-reply .to-name {
  display: none;
}
.response-list-item-view.truncated .response-body {
  max-height: 200px;
}
.response-list-item-view.truncated .response-read-more {
  display: block;
}
.response-list-item-view.expanded .response-body {
  max-height: 100%;
}
.response-list-item-view.highlighted {
  border-left: 2px solid #0d71fb;
}

.response-who {
  position: absolute;
  left: 5px;
  width: 50px;
}

*[class*="response-depth-"] {
  margin-left: 200px;
}

.response-depth-1 {
  margin-left: 0px;
}

.response-depth-2 {
  margin-left: 50px;
}

.response-depth-3 {
  margin-left: 100px;
}

.response-depth-4 {
  margin-left: 150px;
}

.response-depth-1 {
  margin-left: 0px;
  padding-top: 10px;
}

.response-depth-1:not(:first-child) {
  border-top: 1px solid #d4d4d4;
}

@media screen and (max-width: 500px) {
  .response-list-item-view .response-detail {
    margin-left: 25px;
  }
  *[class*="response-depth-"] {
    margin-left: 150px;
  }
  .response-depth-1 {
    margin-left: 0px;
  }
  .response-depth-2 {
    margin-left: 50px;
  }
  .response-depth-3 {
    margin-left: 100px;
  }
}
