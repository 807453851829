.LibraryBrowse,
.LibraryBrowse .LibraryBrowse__container {
  min-height: 100%;
}

.LibraryBrowse .LibraryBrowse__container {
  display: flex;
  flex-direction: column;
}

.SchoolBanner {
  min-height: 200px;
  height: 200px;
}

.Button.LibraryBrowse__edit {
  position: absolute;
  top: 70px;
  right: 30px;
}
@media screen and (max-width: 767px) {
  .LibraryBrowse__edit i + span {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .LibraryBrowse__edit {
    top: 60px;
    right: 5px;
  }
}

.LibraryBrowse .BrowseContainer {
  flex-grow: 1;
  padding-bottom: 2rem;
}
