.FeatureBanners {
  background-color: #888;
  min-height: 50px;
}
.FeatureBanners .Banner {
  padding: 120px 30px 70px;
}
.FeatureBanners .Banner h1 {
  font-size: 30px;
  font-weight: normal;
}
