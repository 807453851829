.progress-green {
  color: #3aba75;
}

.FilePickerUpload {
  text-align: center;
}
.FilePickerUpload .FilePickerUpload__instructions {
  color: #828282;
}
