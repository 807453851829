.progress-green {
  color: #3aba75;
}

.discussion-response-form-view {
  position: relative;
}
.discussion-response-form-view > pw-avatar {
  display: block;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.discussion-response-form-view .response-body-text {
  padding-left: 50px;
  width: 100%;
}
.discussion-response-form-view .response-form-prompt {
  margin: 0px;
  padding-left: 50px;
}
.discussion-response-form-view .response-form-prompt input#id_answer_prompt {
  padding: 0px 5px;
  height: 35px;
  min-height: 35px;
  color: #9c9c9c;
  pointer-events: none;
}
.discussion-response-form-view #id_response_body.plain-text {
  padding: 5px;
  margin-bottom: 10px;
}
@media screen and (max-width: 500px) {
  .discussion-response-form-view .response-body-text {
    padding-left: 25px;
  }
  .discussion-response-form-view .response-form-prompt {
    padding-left: 25px;
  }
  .discussion-response-form-view .btn-default {
    display: block;
    width: 100%;
  }
  .discussion-response-form-view > pw-avatar.small {
    left: 0px;
  }
  .discussion-response-form-view > pw-avatar.small a {
    width: 20px;
    height: 20px;
    line-height: 20.66667px;
    font-size: 9.33489px;
  }
  .discussion-response-form-view > pw-avatar.small a img {
    width: 20px;
    height: 20px;
  }
}
