.Stack {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  backface-visibility: hidden;
  list-style-type: none;
}

.Stack__item {
  position: relative;
  flex-grow: 1;
  display: flex;
}

.Stack__item > * {
  flex-shrink: 0;
}

.Stack__item:last-child {
  flex-shrink: 0;
}

.Stack__item:first-child:last-child {
  flex-grow: 0;
}
