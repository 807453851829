.progress-green {
  color: #3aba75;
}

.discussion-responses-region {
  height: 100%;
}

.response-list-view {
  padding: 10px 20px;
}
.response-list-view .response-filter-container {
  margin-bottom: 10px;
}
.response-list-view .discussion-empty-message {
  color: #828282;
}
.response-list-view.responding
  .response-list-item-view
  .response-meta
  .response-reply {
  display: none;
}
@media screen and (max-width: 500px) {
  .response-list-view {
    padding: 5px;
  }
}
