.Divider {
  margin: 10px 0;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  color: #222;
  font-size: 13px;
}
.Divider:before,
.Divider:after {
  content: "";
  position: relative;
  width: 50%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.Divider label {
  font-weight: normal;
  padding: 0 5px;
  white-space: nowrap;
}

.Divider--inverted {
  color: white;
}
.Divider--inverted:before,
.Divider--inverted:after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
