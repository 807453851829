.FieldWrapper {
  display: flex;
  font-size: 16px;
  text-align: left;
  padding: 10px 25px;
}

.FieldWrapper__label {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  width: 85px;
  margin: 0;
  padding: 9px 10px 5px 0;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.FieldWrapper__field {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.FieldWrapper__input {
  flex-grow: 1;
}
.FieldWrapper__input input,
.FieldWrapper__input textarea {
  display: block;
  padding: 5px;
  min-height: 35px;
  background-color: transparent;
  line-height: 25px;
  font-size: 16px;
  color: #222;
  outline: none;
  border: none;
  border-radius: 4px;
  border: 1px solid #ddd;
}
.FieldWrapper__input input[type="number"] {
  -moz-appearance: textfield;
}
.FieldWrapper__input input::-webkit-outer-spin-button,
.FieldWrapper__input input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.FieldWrapper__input textarea::-webkit-input-placeholder {
  color: #aaa;
}

.FieldWrapper__input textarea:-ms-input-placeholder {
  color: #aaa;
}

.FieldWrapper__input textarea::-moz-placeholder {
  color: #aaa;
}

.FieldWrapper__input textarea:-moz-placeholder {
  color: #aaa;
}

.FieldWrapper__input input::-webkit-input-placeholder {
  color: #aaa;
}

.FieldWrapper__input input:-ms-input-placeholder {
  color: #aaa;
}

.FieldWrapper__input input::-moz-placeholder {
  color: #aaa;
}

.FieldWrapper__input input:-moz-placeholder {
  color: #aaa;
}

.FieldWrapper__status {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 15px;
  min-height: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #888;
}

.FieldWrapper__custom {
  padding-left: 5px;
  line-height: 25px;
  color: #888;
}

.FieldWrapper__tooltip {
  display: inline-block;
  padding-left: 3px;
  line-height: 0;
}

.FieldWrapper__tooltip .Tooltip {
  z-index: 100;
  min-width: 150px;
  max-width: 250px;
  white-space: normal;
}

.FieldWrapper--inline {
  padding: 0;
}

.FieldWrapper--minimal .FieldWrapper__input input,
.FieldWrapper--minimal .FieldWrapper__input textarea {
  padding: 0;
  min-height: 25px;
  border-width: 0;
  border-radius: 0;
  border-bottom: 1px dotted #888;
  background-color: transparent !important;
}

.FieldWrapper--focus .FieldWrapper__label {
  color: #6a9fcb;
}

.FieldWrapper--focus .FieldWrapper__input input,
.FieldWrapper--focus .FieldWrapper__input textarea {
  background-color: transparent;
  border-color: #6a9fcb;
  border-style: solid;
}

.FieldWrapper--error .FieldWrapper__label,
.FieldWrapper--error .FieldWrapper__status,
.FieldWrapper--error.FieldWrapper--focus .FieldWrapper__label,
.FieldWrapper--error.FieldWrapper--focus .FieldWrapper__status {
  color: #d0011b;
}

.FieldWrapper--error .FieldWrapper__input input,
.FieldWrapper--error .FieldWrapper__input textarea,
.FieldWrapper--error.FieldWrapper--focus .FieldWrapper__input input,
.FieldWrapper--error.FieldWrapper--focus .FieldWrapper__input textarea {
  background-color: #fae5e8;
  border-color: #d0011b;
  border-style: solid;
}

.FieldWrapper--inverted .FieldWrapper__label {
  color: white;
}

.FieldWrapper--inverted .FieldWrapper__input input,
.FieldWrapper--inverted .FieldWrapper__input textarea {
  color: white;
}

.FieldWrapper--inverted .FieldWrapper__input input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.FieldWrapper--inverted .FieldWrapper__input input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.FieldWrapper--inverted .FieldWrapper__input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.FieldWrapper--inverted .FieldWrapper__input input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.FieldWrapper--toplabel {
  flex-direction: column;
}
.FieldWrapper--toplabel .FieldWrapper__label {
  padding: 0;
  max-width: 100%;
  width: auto !important;
}
.FieldWrapper--toplabel .FieldWrapper__input {
  padding-top: 0;
}

@media screen and (max-width: 480px) {
  .FieldWrapper {
    flex-direction: column;
  }
  .FieldWrapper .FieldWrapper__label {
    padding: 0;
    max-width: 100%;
    width: auto !important;
  }
  .FieldWrapper .FieldWrapper__input {
    padding-top: 0;
  }
}
