.CoverPreviewer {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
  background-color: #333;
  background-size: cover;
  background-position: center center;
  background-color: #666;
}

.CoverUploader label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.CoverUploader label:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.CoverUploader i {
  font-size: 17px;
  display: block;
}

html.no-touch .ImageUpload--has-file:not(:hover) .CoverUploader label {
  display: none;
}
