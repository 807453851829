.Dropdown__Trigger {
  position: relative;
  display: inline-block;
}

.Dropdown__Content {
  z-index: 9001;
  overflow: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 3px 0;
}
