.AppPanelLinks {
  min-height: calc(100% - 100px);
  padding-top: 2px;
  padding-bottom: 15px;
}

.AppPanelLinks__list {
  position: relative;
  padding-bottom: 1em;
}

.AppPanelLinks__list + .AppPanelLinks__list {
  padding-top: 1em;
}

.AppPanelLinks__list + .AppPanelLinks__list:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  left: 30px;
  right: calc(100% - 30px);
  width: calc(100% - 60px);
  height: 1px;
}

.AppPanelLinks__list .Button {
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
}

.AppPanelLinks__list .Button .Button__content-wrapper {
  justify-content: flex-start;
}

/* .AppPanelLinks__primary .Button,
.AppPanelLinks__primary a {
  font-size: 17px;
  line-height: 17px;
} */

.AppPanelLinks__header {
  padding: 0 30px;
  margin: 0;
}

.AppPanelLinks__divider {
  margin-bottom: 15px;
}

.AppPanelLinks__activate {
  padding: 30px;
}
.AppPanelLinks__activate .Button {
  display: block;
  margin-left: -2px;
  margin-bottom: 5px;
}
