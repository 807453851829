.Gate {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  cursor: pointer;
  color: #aaa;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 30px 10px 20px;
  margin-bottom: 20px;
}

.Gate:hover {
  color: #666;
}

.Gate__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 75px;
}

.Gate__icon {
  min-width: 20px;
  margin-right: 15px;
  font-size: 1.5em;
}

.Gate__text {
  text-align: left;
  overflow: hidden;
}

.Gate__text h2,
.Gate__text p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Gate__text h2 {
  font-family: -apple-system, ".SFNSText-Bold", "San Francisco",
    "ProximaNova-Bold", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-size: 20px;
}

.Gate__text p {
  font-size: 16px;
}

@media screen and (max-width: 480px) {
  .Gates {
    padding-left: 0;
    padding-right: 0;
  }

  .Gates .Gates__wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
