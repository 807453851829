@font-face {
  font-family: "ProximaNova-Light";
  src: url("../../../assets/css/fonts/proxima-nova/ProximaNova-LightWeb.eot");
  src: url("../../../assets/css/fonts/proxima-nova/ProximaNova-LightWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/proxima-nova/ProximaNova-LightWeb.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-LightIt";
  src: url("../../../assets/css/fonts/proxima-nova/ProximaNova-LightItWeb.eot");
  src: url("../../../assets/css/fonts/proxima-nova/ProximaNova-LightItWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/proxima-nova/ProximaNova-LightItWeb.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../../../assets/css/fonts/proxima-nova/270A48_2_0.eot");
  src: url("../../../assets/css/fonts/proxima-nova/270A48_2_0.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/proxima-nova/270A48_2_0.woff") format("woff"),
    url("../../../assets/css/fonts/proxima-nova/270A48_2_0.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-RegularIt";
  src: url("../../../assets/css/fonts/proxima-nova/270A48_0_0.eot");
  src: url("../../../assets/css/fonts/proxima-nova/270A48_0_0.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/proxima-nova/270A48_0_0.woff") format("woff"),
    url("../../../assets/css/fonts/proxima-nova/270A48_0_0.ttf")
      format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../../../assets/css/fonts/proxima-nova/270A48_3_0.eot");
  src: url("../../../assets/css/fonts/proxima-nova/270A48_3_0.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/proxima-nova/270A48_3_0.woff") format("woff"),
    url("../../../assets/css/fonts/proxima-nova/270A48_3_0.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-BoldIt";
  src: url("../../../assets/css/fonts/proxima-nova/270A48_1_0.eot");
  src: url("../../../assets/css/fonts/proxima-nova/270A48_1_0.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/proxima-nova/270A48_1_0.woff") format("woff"),
    url("../../../assets/css/fonts/proxima-nova/270A48_1_0.ttf")
      format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "FreightDisplay-Book";
  src: url("../../../assets/css/fonts/freight-display/FreigDisProBooWeb.eot");
  src: url("../../../assets/css/fonts/freight-display/FreigDisProBooWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/freight-display/FreigDisProBooWeb.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FreightDisplay-BookIt";
  src: url("../../../assets/css/fonts/freight-display/FreigDisProBooItaWeb.eot");
  src: url("../../../assets/css/fonts/freight-display/FreigDisProBooItaWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/freight-display/FreigDisProBooItaWeb.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "FreightText-Book";
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBookWeb.eot");
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBookWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/freight-text/FreigTexProBookWeb.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FreightText-BookIt";
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBookItWeb.eot");
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBookItWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/freight-text/FreigTexProBookItWeb.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "FreightText-Bold";
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBoldWeb.eot");
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBoldWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/freight-text/FreigTexProBoldWeb.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "FreightText-BoldIt";
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBoldItWeb.eot");
  src: url("../../../assets/css/fonts/freight-text/FreigTexProBoldItWeb.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/css/fonts/freight-text/FreigTexProBoldItWeb.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
}
