.SelectInput .Select-control {
  box-shadow: none !important;
  border-radius: 4px;
  border: 1px solid #ddd !important;
  background-color: transparent !important;
}

.SelectInput .Select-menu-outer {
  z-index: 9999999990 !important;
  border-radius: 0 0 4px 4px;
  border-color: #6a9fcb;
  border-top-width: 0;
}

.SelectInput .Select-input > input {
  border: none;
  padding: 0;
  border-width: 0;
}

.SelectInput .Select-option {
  color: #222;
  background-color: transparent;
}

.SelectInput .Select-option.is-focused {
  background-color: rgba(0, 0, 0, 0.05);
}

.SelectInput .Select--multi .Select-value {
  color: #222;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  border: none;
}

.SelectInput .Select--multi .Select-value-icon {
  border-right-color: rgba(0, 0, 0, 0.1);
  color: #222;
}

.SelectInput .Select--multi .Select-value-icon:hover,
.SelectInput .Select--multi .Select-value-icon:focus {
  color: #222;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px 0 0 50px;
}

.SelectInput .is-focused .Select-control {
  background-color: transparent !important;
  border-color: #6a9fcb !important;
}

.SelectInput .is-disabled .Select-value {
  cursor: not-allowed;
}

.SelectInput .is-disabled .Select-value-label {
  color: #888 !important;
}

.SelectInput .is-disabled .Select-control {
  border-color: transparent !important;
}

.SelectInput .Select-control .Select-input:focus {
  background: transparent !important;
}

.SelectInput.FieldStyler--inline .Select-input {
  position: absolute !important;
  top: 0 !important;
}

.SelectInput.FieldStyler--inline .Select-value-label {
  display: block !important;
}

.SelectInput.FieldStyler--inline .Select-placeholder,
.SelectInput.FieldStyler--inline .Select-value {
  position: relative !important;
}

.SelectInput.FieldStyler--minimal .Select-control {
  height: 25px;
  padding: 0;
  border-radius: 0;
  border-width: 0 !important;
  border-top: 1px solid transparent !important;
  border-bottom: 1px dotted #888 !important;
  background-color: transparent !important;
}

.SelectInput.FieldStyler--minimal .Select-placeholder,
.SelectInput.FieldStyler--minimal .Select-value {
  padding: 0 !important;
  line-height: 25px !important;
}

.SelectInput.FieldStyler--minimal .Select-arrow-zone {
  width: 20px;
  padding-right: 0;
}

/* Override white background. Why does react-select set the background to white? */
.SelectInput .Select-control .Select-input:focus {
  background: transparent !important;
}
