.ToggleInput .FieldWrapper__input {
  display: flex;
  align-items: center;
  flex-grow: 0;
}

@media screen and (max-width: 480px) {
  .ToggleInput {
    flex-direction: row;
  }
  .ToggleInput .FieldWrapper__label {
    flex-shrink: 1;
    padding-right: 10px;
  }
  .ToggleInput .FieldWrapper__field {
    justify-content: flex-end;
  }
  .ToggleInput .ToggleInput__switch {
    flex-direction: row-reverse;
  }
}

.ToggleInput--align-right .FieldWrapper__label {
  flex-shrink: 1;
  padding-right: 10px;
}

.ToggleInput--align-right .FieldWrapper__field {
  justify-content: flex-end;
}

.ToggleInput--align-right .ToggleInput__switch {
  flex-direction: row-reverse;
}

.ToggleInput__switch {
  width: 100%;
  display: flex;
  align-items: center;
}
.ToggleInput__switch .LoadingCircle {
  position: relative;
  margin-right: 5px;
}
