.ImageUpload {
  position: relative;
  box-shadow: none;
  border: none;
}
.ImageUpload > label {
  position: absolute;
  left: 20px;
  top: 10px;
  color: white;
  font-size: 12px;
}

.AtmospherePreviewer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  text-align: center;
  background-color: #333;
}

.AtmospherePreviewer__bgd {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.AtmospherePreviewer__img {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background-size: cover;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.AtmosphereUploader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 15px;
}
.AtmosphereUploader:hover label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.AtmosphereUploader label {
  display: none;
  margin: 0 auto;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.AtmosphereUploader i {
  margin-bottom: 0.25em;
  display: block;
}
.AtmosphereUploader .LoadingSpinner {
  width: 100%;
  height: 100%;
}

.ImageUpload--avatar .AtmospherePreviewer__img,
.ImageUpload--avatar .AtmosphereUploader label {
  width: 75px;
  height: 75px;
  border-radius: 75px;
}

.ImageUpload--cover .AtmospherePreviewer__img,
.ImageUpload--cover .AtmosphereUploader label {
  width: 100px;
  height: 75px;
}

.ImageUpload--default .AtmosphereUploader label {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
