.PricingOverlay {
  background-color: rgba(255, 255, 255, 0.85);
}
.PricingOverlay iframe {
  position: relative;
}
.PricingOverlay .ScrollView {
  height: 100%;
}
.PricingOverlay .IconButton.PricingOverlay__exit {
  z-index: 1000;
  position: absolute !important;
  top: 10px;
  left: 10px;
}
