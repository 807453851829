.ColorPickerToggle {
  width: 100%;
}
.ColorPickerToggle .Dropdown__Trigger {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .ColorPickerToggle {
    flex-direction: row;
  }
  .ColorPickerToggle .FieldWrapper__input {
    justify-content: flex-end;
  }
}

.ColorPickerToggle .FieldWrapper__input {
  display: flex;
  align-items: center;
}

.ColorPickerToggle__dropdown {
  max-width: 400px;
  width: 100vw;
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.ColorPickerToggle__button {
  overflow: hidden;
  position: relative;
  height: 20px;
  width: 40px;
}
.ColorPickerToggle__button > div {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}
