.GateButton {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  cursor: pointer;
  color: #666;
  background-color: white;
  border-radius: 5px !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 20px;
  outline: none;
  border: none;
  transition: transform 0.3s ease;
}

.GateButton:hover {
  color: #222;
  background-color: white;
  transform: scale(1.01);
}

.GateButton__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.GateButton__content:only-child {
  height: 75px;
  margin-bottom: 0;
}

.GateButton__icon {
  min-width: 20px;
  margin-right: 15px;
  font-size: 1.5em;
}

.GateButton__arrow {
  min-width: 20px;
  margin-left: 15px;
  font-size: 1.5em;
}

.GateButton__text {
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
}
