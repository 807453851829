.FieldFooter {
  padding: 0 25px 20px;
  line-height: 20px;
  font-size: 14px;
  color: #888;
}
@media screen and (max-width: 480px) {
  .FieldFooter {
    margin-left: 0 !important;
  }
}
