.progress-green {
  color: #3aba75;
}

.response-list-item-archived-view {
  border-top: 0px !important;
  padding: 0px !important;
  border: 0px !important;
  font-style: italic;
  color: #828282;
}
