@font-face {
  font-family: "TTHoves";
  src: url("./tthoves/tthoves-bold.eot");
  src: url("./tthoves/tthoves-bold.eot?#iefix") format("embedded-opentype"),
    url("./tthoves/tthoves-bold.woff") format("woff"),
    url("./tthoves/tthoves-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TTHoves";
  src: url("./tthoves/tthoves-demi-bold.eot");
  src: url("./tthoves/tthoves-demi-bold.eot?#iefix") format("embedded-opentype"),
    url("./tthoves/tthoves-demi-bold.woff") format("woff"),
    url("./tthoves/tthoves-demi-bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TTHoves";
  src: url("./tthoves/tthoves-medium.eot");
  src: url("./tthoves/tthoves-medium.eot?#iefix") format("embedded-opentype"),
    url("./tthoves/tthoves-medium.woff") format("woff"),
    url("./tthoves/tthoves-medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TTHoves";
  src: url("./tthoves/tthoves-regular.eot");
  src: url("./tthoves/tthoves-regular.eot?#iefix") format("embedded-opentype"),
    url("./tthoves/tthoves-regular.woff") format("woff"),
    url("./tthoves/tthoves-regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TTHoves";
  src: url("./tthoves/tthoves-regular-italics.eot");
  src: url("./tthoves/tthoves-regular-italics.eot?#iefix")
      format("embedded-opentype"),
    url("./tthoves/tthoves-regular-italics.woff") format("woff"),
    url("./tthoves/tthoves-regular-italics.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "TTHoves";
  src: url("./tthoves/tthoves-hairline.eot");
  src: url("./tthoves/tthoves-hairline.eot?#iefix") format("embedded-opentype"),
    url("./tthoves/tthoves-hairline.woff") format("woff"),
    url("./tthoves/tthoves-hairline.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
