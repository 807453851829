.TextInput input {
  width: 100%;
}

.TextInput input[placeholder] {
  text-overflow: ellipsis;
}

.TextInput__input {
  position: relative;
}

/* Edge fix where <style> appearing as child of .TextInput__input and displays in UI */
.TextInput__input style {
  display: none !important;
}

.TextInput--autosize input {
  min-height: auto !important;
}

.TextInput--autosize .FieldStyler__input {
  flex-grow: 0;
}

.TextInput--autosize .FieldStyler__status {
  overflow: visible;
  white-space: nowrap;
}

/* TODO: handle inverted case */
.TextInput input:disabled {
  border-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.075);
}
