.progress-green {
  color: #3aba75;
}

body,
html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
video,
object {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a.link-action {
  white-space: nowrap;
}

.alternate-action {
  margin-top: 10px;
  display: inline-block;
}

.hr-with-label {
  text-align: center;
  position: relative;
  margin: 10px 0px;
}
.hr-with-label span,
.hr-with-label label {
  display: inline-block;
  color: #828282;
}
.hr-with-label span:before,
.hr-with-label span:after,
.hr-with-label label:before,
.hr-with-label label:after {
  border-top: 1px solid #d4d4d4;
  display: block;
  height: 1px;
  content: " ";
  width: 45%;
  position: absolute;
  left: 0;
  top: 0.7em;
}
.hr-with-label span:after,
.hr-with-label label:after {
  right: 0px;
  left: auto;
}

a.action-link {
  text-transform: uppercase;
  color: #666;
  font-size: 12px;
  border-bottom: 1px solid #dfdfdf;
}

.progress-green {
  color: #3aba75;
}

.btn {
  color: #0d71fb;
  border: 0;
  background: none;
  padding: 0;
  border: 1px solid #0d71fb;
  padding: 5px 16px;
  border-radius: 20px;
}
.btn:active,
.btn.active {
  box-shadow: none;
}

.btn [class^="pwai-"],
.btn [class*=" pwai-"] {
  vertical-align: middle;
}

.btn-default {
  color: #0d71fb;
  background-color: #ffffff;
  border-color: #cccccc;
}
.btn-default[disabled] {
  color: #999;
}
.btn-default:hover {
  color: #035ad2;
}

.btn-primary {
  padding: 8px 16px;
  font-size: 16px;
  color: #0d71fb;
  background-color: #fff;
  border-color: #71acfd;
}
.btn-primary.disabled,
.btn-primary:hover.disabled {
  border-color: #b4b4b4;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .btn-primary.dropdown-toggle {
  color: #0d71fb;
  background-color: #ebebeb;
  border-color: #0356c8;
}
.btn-primary:hover {
  border-color: #0d71fb;
}
.btn-primary:active,
.btn-primary.active,
.open .btn-primary.dropdown-toggle {
  background-image: none;
  background-color: #0d71fb;
  color: #fff;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  color: #999;
  background-color: #fff;
  border-color: #71acfd;
}

.btn-link {
  border: 0px;
  color: #0d71fb;
  background-color: transparent;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .btn-link.dropdown-toggle {
  color: #0d71fb;
}
.btn-link:hover {
  border-color: transparent;
}
.btn-link:active,
.btn-link.active,
.open .btn-link.dropdown-toggle {
  background-image: none;
  background-color: #0d71fb;
  color: transparent;
}
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link[disabled].active,
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #999;
  background-color: transparent;
}
.btn-link:active,
.btn-link.active,
.open .btn-link.dropdown-toggle {
  background-color: transparent !important;
}

.btn-danger {
  color: #fff;
  background-color: #ff4136;
  border-color: #ffa19c;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff1a0d;
  border-color: #f80e00;
}
.btn-danger:hover {
  border-color: #ff4136;
}
.btn-danger:active,
.btn-danger.active,
.open .btn-danger.dropdown-toggle {
  background-image: none;
  background-color: #fff;
  color: #ff4136;
}
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  color: #999;
  background-color: #ff4136;
  border-color: #ffa19c;
}

.btn-error {
  color: #ff4136 !important;
  border-color: #ff4136 !important;
  background-color: #fff !important;
}
.btn-error.shake {
  -webkit-animation-duration: 1000ms;
  -moz-animation-duration: 1000ms;
  animation-duration: 1000ms;
}

body.touch .btn-default,
body.touch .btn-primary {
  background-color: none !important;
}
body.touch .btn-default:hover,
body.touch .btn-default:focus,
body.touch .btn-default:active,
body.touch .btn-primary:hover,
body.touch .btn-primary:focus,
body.touch .btn-primary:active {
  background-color: none !important;
}
