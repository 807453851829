.Alert {
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 5px;
  align-items: center;
  padding: 5px;
  max-width: 500px;
  margin: 20px auto;
  font-size: 14px;
}

.Alert__icon {
  padding: 0 10px;
  font-size: 18px;
  color: black;
}

.Alert > p,
.Alert > span {
  margin: 0;
  padding: 5px;
  word-break: break-word;
}

.Alert--error {
  color: black;
  background-color: #fffce6;
}

.Alert--success {
  color: #3ca63d;
  background-color: #e6f9e8;
}

.Alert--info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
}

.Alert--inverted {
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
}

.Alert.Alert--clear {
  justify-content: space-between;
  grid-template-columns: min-content 1fr min-content;
}

.Alert--download {
  display: flex;
  align-items: flex-start;
  color: #333;
  background-color: #fff;
  text-align: left;
  width: 250px;
}

.Alert--download .IconButton .Button__content-wrapper {
  justify-content: flex-start;
}

.Alert--download__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Alert--download__subtext {
  color: lightgray;
}
