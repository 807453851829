.AppNavbar {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  display: grid;
  /* fit "unknown" number of columns as the SchoolIdentity and other parts(?) of the navbar may not always be included as children */
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.AppNavbar--minimal {
  background-color: transparent;
}
.AppNavbar__nav {
  display: flex;
}

.AppNavbar__nav .Button {
  height: 50px;
}
.AppNavbar .Button .Button__content-wrapper {
  height: 100%;
}
.AppNavbar__nav .Button i + span {
  margin-left: 5px;
}
@media screen and (max-width: 500px) {
  .AppNavbar__nav .Button i + span {
    display: none;
  }
}
.AppNavbar__nav .Button + .Button:before {
  content: "";
  position: absolute;
  bottom: 15px;
  top: 15px;
  left: 0;
  height: 20px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.AppNavbar__user {
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}
