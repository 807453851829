.progress-green {
  color: #3aba75;
}

.discussion-who {
  text-align: center;
  position: absolute;
  left: 25px;
  top: 10px;
}

.discussion-detail {
  padding: 0px;
  margin-left: 50px;
  max-width: 600px;
  border-radius: 2px;
}
.discussion-detail .discussion-type {
  text-transform: capitalize;
  display: block;
  color: #828282;
}
.discussion-detail a.discussion-title {
  display: inline-block;
  color: #000;
  font-size: 1.5em;
  line-height: 1.2em;
  margin: 0px;
}
.discussion-detail a.discussion-title:hover {
  text-decoration: none;
}

.discussion-type-label {
  text-transform: capitalize;
}

.discussion-stats {
  position: absolute;
  right: 20px;
  top: 23px;
  display: inline-block;
}
.discussion-stats > span,
.discussion-stats > div {
  display: inline-block;
  font-size: 1em;
  margin-right: 10px;
  color: #9c9c9c;
}
.discussion-stats > span a,
.discussion-stats > div a {
  color: #9c9c9c;
}
.discussion-stats > span a:hover,
.discussion-stats > div a:hover {
  text-decoration: none;
}

.discussion-meta {
  vertical-align: middle;
  color: #828282;
}
.discussion-meta > span {
  vertical-align: middle;
  display: inline-block;
  margin-right: 15px;
}
.discussion-meta > span a {
  color: #828282;
}
.discussion-meta > span a:hover {
  text-decoration: none;
}
.discussion-meta > span.meta-dividor {
  margin: 0px 5px;
}
.discussion-meta a.discussion-context-link {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  top: -1px;
  position: relative;
}

.discussion-admin-menu {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1000;
}
.discussion-admin-menu a {
  color: #828282;
}
.discussion-admin-menu a:hover {
  text-decoration: none;
}
.discussion-admin-menu a.dropdown-toggle {
  font-size: 1em;
  color: #b5b5b5;
}
.discussion-admin-menu .ask-to-answer {
  padding: 5px 0;
}
.discussion-admin-menu .ask-to-answer label {
  padding: 0 20px;
  font-weight: normal;
}
.discussion-admin-menu .ask-to-answer ul {
  margin-top: 5px;
}
.discussion-admin-menu .ask-to-answer ul li a {
  color: #444;
  padding: 0 20px;
  white-space: nowrap;
  position: relative;
  display: block;
  vertical-align: middle;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.discussion-admin-menu .ask-to-answer ul li a img {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin-right: 10px;
}
.discussion-admin-menu .ask-to-answer ul li a.notified:before {
  content: "";
  display: block;
  position: absolute;
  left: -10px;
  top: 12px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
}
.discussion-admin-menu .dropdown-menu {
  z-index: 100000;
  left: auto;
  right: 20px;
  min-width: 100px;
  margin-top: -4px;
  width: 150px;
}

.no-touch .discussion-list-item-view .discussion-admin-menu,
.no-touch .response-list-item-view .discussion-admin-menu {
  /* display: none;  */
  display: block;
  opacity: 0;
}
.no-touch .discussion-list-item-view .discussion-admin-menu .dropdown-menu,
.no-touch .response-list-item-view .discussion-admin-menu .dropdown-menu {
  right: 0;
}
.no-touch .discussion-list-item-view .discussion-admin-menu.open,
.no-touch .response-list-item-view .discussion-admin-menu.open {
  display: block;
}

.no-touch .discussion-list-item-view:hover .discussion-admin-menu,
.no-touch .response-list-item-view:hover .discussion-admin-menu {
  /* display: block;  */
  opacity: 1;
}

a.discussion-title .no-touch .discussion-detail:hover {
  color: #0d71fb;
}

.no-touch .discussion-stats > span a:hover,
.no-touch .discussion-stats > div a:hover {
  color: #0d71fb;
}

.no-touch .discussion-meta > span a:hover {
  color: #0d71fb;
}

.paginate {
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: relative;
}
.paginate .spinner {
  display: inline-block;
}

@media screen and (max-width: 500px) {
  .response-who {
    left: 2.5px !important;
    width: 25px !important;
  }
  .response-who pw-avatar.small a {
    width: 20px;
    height: 20px;
    line-height: 20.66667px;
    font-size: 9.33489px;
  }
  .response-who pw-avatar.small a img {
    width: 20px;
    height: 20px;
  }
  form .pw-form-cancel {
    padding: 8px 0px;
    display: block;
    text-align: center;
  }
  .discussion-who pw-avatar.small a {
    width: 20px;
    height: 20px;
    line-height: 20.66667px;
    font-size: 9.33489px;
  }
  .discussion-who pw-avatar.small a img {
    width: 20px;
    height: 20px;
  }
  .discussion-detail {
    margin-right: 30px;
    margin-left: 30px;
  }
  .discussion-detail a.discussion-title {
    font-size: 1em;
  }
  .discussion-meta .meta-dividor {
    display: none;
  }
  .discussion-stats {
    font-size: 0.8em;
    right: 0px;
    top: 3px;
    text-align: right;
    z-index: 10;
    background-color: #fff;
  }
  .discussion-stats span {
    display: block;
  }
  .discussion-stats span i.fa {
    font-size: 0.8em;
  }
}
