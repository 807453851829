.RadioGroup .FieldStyler--inline {
  display: inline-block;
}

.RadioGroup .FieldStyler__input input,
.RadioGroup .FieldStyler__input textarea {
  padding: 2px 5px;
  min-height: 25px;
}

.RadioGroup .FieldStyler__input input {
  height: 26px;
}

.RadioGroup .TextInput,
.RadioGroup .TextInput > .FieldStyler__field {
  position: static;
}

.RadioGroup .DatePicker .FieldStyler__input {
  flex-grow: 0;
}

.RadioGroup__description {
  margin: -5px 0 5px;
  color: #666;
}
