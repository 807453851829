.InviteFormFooter {
  flex-grow: 0;
  flex-shrink: 0;
  background-color: whitesmoke;
  border-radius: 0 0 10px 10px;
  padding: 15px 50px;
}

.InviteFormFooter--invalid {
  background-color: #fae5e8;
}

.InviteFormFooter__actions {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.InviteFormFooter__actions .SelectInput {
  flex-grow: 1;
}

@media screen and (min-width: 479px) {
  .InviteFormFooter .SelectInput.SelectRole {
    margin-right: 5px;
  }
}
@media screen and (max-width: 480px) {
  .InviteFormFooter {
    border-radius: 0;
    padding: 10px 20px;
  }
  .InviteFormFooter .SubmitStatusSelectButton {
    font-size: 14px !important;
  }

  .InviteFormFooter__actions {
    flex-direction: column;
  }

  .InviteFormFooter__actions .SelectInput {
    width: 100%;
    margin-bottom: 10px;
  }
}
