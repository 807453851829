.InviteField {
  position: relative;
  display: flex;
  margin: 15px 0;
  align-items: center;
  border-radius: 4px;
}
.InviteField .Avatar {
  margin-right: 10px;
}
.InviteField .IconButton {
  margin-left: 5px;
}
.InviteField .IconButton:hover {
  color: #d0011b !important;
}

html.no-touch .InviteField:not(:hover) .IconButton {
  opacity: 0;
}

.InviteField__meta {
  position: relative;
  flex-grow: 1;
  padding: 2px 6px;
}

.InviteField__user {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
}

.InviteField__email {
  display: block;
  margin: 0;
}

.InviteField__user + .InviteField__email {
  line-height: 15px;
  font-size: 14px;
}

.InviteField__error {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 12px;
  color: #d0011b;
}
