/* Style overrides to keep form styles uniform */
.GroupDetailsForm h5 {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}

.GroupDetailsForm label h5 {
  color: rgba(0, 0, 0, 1);
}
