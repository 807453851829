.LoadingCircle,
.LoadingCircle--center {
  display: inline-block;
  border-radius: 50%;
  animation: smoothspin 1s linear infinite;
}

/*
  when named "spin", noticing that the animation sets the following style:
  transform: rotate(360deg) translate3d(0px, -50%, 0px);

  changing the animation name to something other than "spin" produces the expected style:
  transform: rotate(1turn);
*/
@keyframes smoothspin {
  100% {
    transform: rotate(1turn);
  }
}

.LoadingCircle--center {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}
