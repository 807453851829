.InvitationControls__link_menu {
  padding: 5px 10px 10px;
}

.InvitationControls__link_prompt {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.InvitationControls__link_prompt h5 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  color: black;
}

.InvitationControls__link_field {
  min-width: 280px;
  display: flex;
  color: #888;
}
.InvitationControls__link_field input {
  width: 100px;
  flex-grow: 1;
  outline: none;
  padding-left: 5px;
  border: 1px solid #ddd;
  border-right: none;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  line-height: 24px;
}
.InvitationControls__link_field .Button {
  border-radius: 0 4px 4px 0 !important;
}
