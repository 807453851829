.InviteLookup {
  position: relative;
  width: 100%;
}

.InviteLookup--focus {
  z-index: 9001;
}

.Card:not(:last-child) .InviteLookup--focus {
  z-index: 0;
}

.InviteLookup__loader {
  position: absolute;
  right: 10px;
  left: auto;
}
