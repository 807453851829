.progress-green {
  color: #3aba75;
}

.filter-menu-view {
  position: relative;
  font-size: 1em;
  padding: 0;
}
.filter-menu-view a {
  display: block;
  padding: 5px 16px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-menu-view .dropdown-toggle.open-text {
  position: relative;
  padding-right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.filter-menu-view .dropdown-toggle.open-text .pwai-chevron-down {
  font-size: 1.4em;
  position: absolute;
  right: 0;
}
.filter-menu-view.open .dropdown-menu > li.selected a {
  font-weight: bold;
}

.collection-filtering {
  opacity: 0.5;
  pointer-events: none;
}
