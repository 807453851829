.ColorPicker__fields {
  position: relative;
  width: 100%;
  padding: 12px 20px;
}
.ColorPicker__fields > div + div {
  margin-top: 10px;
}

.ColorPicker__hue,
.ColorPicker__saturation,
.ColorPicker__alpha {
  position: relative;
  width: 100%;
}

.ColorPicker__saturation {
  height: 150px;
}

.ColorPicker__hue,
.ColorPicker__alpha {
  height: 12px;
}

.ColorPicker__inputs {
  display: flex;
}
.ColorPicker__inputs .FieldWrapper__label {
  text-transform: capitalize;
}
.ColorPicker__inputs .ColorInput {
  padding: 10px;
}

.ColorPicker__inputs_hex {
  flex-grow: 2;
  padding-left: 10px;
}

.ColorPicker__inputs_rgb {
  display: flex;
  flex-grow: 4;
  padding-right: 10px;
}
.ColorPicker__inputs_rgb .ColorInput {
  width: 70px;
}
@media screen and (max-width: 480px) {
  .ColorPicker__inputs_rgb .ColorInput:last-of-type {
    display: none;
  }
}

.ColorPicker__swatches {
  position: relative;
  width: 100%;
  padding: 12px 20px;
}

.ColorPicker__pointer--circle,
.ColorPicker__pointer--outline {
  width: 16px;
  height: 16px;
  transform: translate(-8px, -2px);
  border-radius: 8px;
}

.ColorPicker__pointer--circle {
  background-color: white;
  border: 1px solid #ccc;
}

.ColorPicker__pointer--outline {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
}
