.InviteLookupResult {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  color: black;
}
.InviteLookupResult h5 {
  margin: 0;
  padding: 0;
  color: #aaa;
}

html.no-touch .InviteLookupResults:hover .InviteLookupResult:hover,
html.no-touch .InviteLookupResults:not(:hover) .InviteLookupResult--highlight {
  background-color: rgba(0, 0, 0, 0.05);
}
html.no-touch
  .InviteLookupResults:hover
  .InviteLookupResult:hover
  .InviteLookupResult__prompt,
html.no-touch
  .InviteLookupResults:not(:hover)
  .InviteLookupResult--highlight
  .InviteLookupResult__prompt {
  display: block;
}

.InviteLookupResult__meta {
  margin-left: 10px;
  flex-grow: 1;
}
.InviteLookupResult__meta h4 {
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
}

.InviteLookupResult__prompt {
  display: none;
  color: #aaa;
  padding: 5px;
}
