.Card {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding-bottom: 10px;
  font-size: 16px;
  margin: 0 auto;
}

.Card:not(.Card--noaction) > .CardContainer .Button.Card__Action,
.Card:not(.Card--noaction) > .CardContainer > button[type="submit"],
.Card:not(.Card--noaction) > .CardContainer form .SubmitStatusButton,
.Card:not(.Card--noaction) > .CardContainer form .FormSubmitStatus,
.Card:not(.Card--noaction) > .CardContainer form .FormStateSubmitButton {
  position: absolute;
  right: 10px;
  top: 6px;
  left: auto;
  transform: initial;
}

/* Have to unapply! FormStateSubmitButton wraps a SubmitButton with
a Tooltip, so needed to wrap the Tooltip hand target the wrapper
with aboslute positioning styles instead. */
.Card:not(.Card--noaction)
  > .CardContainer
  form
  .FormStateSubmitButton
  .SubmitStatusButton {
  position: static;
}

.Card > .CardContainer {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.Card > .CardContainer > .CardBody {
  min-height: 300px;
  position: relative;
}

.Card__Overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  text-align: center;
}

.Card__Overlay .LoadingCircle {
  position: relative;
  top: 40%;
}

.Card__Nav {
  position: relative;
  height: 40px;
}

.Card__Nav h4,
.Card__Nav .Card__Heading {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  pointer-events: none;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Card__Nav .Card__Tooltip {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 100px;
  height: 40px;
}

.Card__Nav .Button:not(.Card__Action).blank {
  position: relative;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
}

.Card__Nav i.pwai-chevron-left {
  position: relative;
  top: 1px;
  margin-left: -5px;
}

.Card__Back {
  position: absolute !important;
  left: 0;
  top: 0;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
}

.Card--navless .Card__Nav {
  position: absolute;
  background-color: transparent;
}

/* When Card has nav followed by only 1 child, then apply flex styles
to the parent. */
.CardBody > div:has(> :last-child:nth-child(2)) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* When Card has nav followed by only 1 child, then make sure that child
is 100% the height minus the 40px for the nav. */
.CardBody > div:has(> :last-child:nth-child(2)) .Card__Nav + * {
  height: calc(100% - 40px);
}

@media screen and (max-width: 480px) {
  .Card:not(.Card--noaction) > .CardContainer .CardBody .Button.Card__Action,
  .Card:not(.Card--noaction) > .CardContainer .CardBody > button[type="submit"],
  .Card:not(.Card--noaction)
    > .CardContainer
    .CardBody
    form
    .SubmitStatusButton,
  .Card:not(.Card--noaction) > .CardContainer .CardBody form .FormSubmitStatus {
    z-index: 1;
    position: fixed;
  }

  .Card__Nav {
    z-index: 1;
    position: sticky;
    top: 0;
  }

  .Card__Nav h4,
  .Card__Nav .Card__Heading {
    font-size: 14px;
    /* display: none; */
  }

  .Card,
  .Card > .CardContainer,
  .Card > .CardContainer > .CardBody {
    min-height: 100vh;
  }
}

@media screen and (min-width: 480px) {
  .Card:not(.velocity-animating) > .CardContainer {
    box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.1);
    transform-origin: top center;
    transition: transform 150ms;
  }

  .Card:nth-last-child(2) > .CardContainer {
    transform: scale(0.95) translateY(-5px);
  }

  .Card:nth-last-child(3) > .CardContainer {
    transform: scale(0.91) translateY(-9px);
  }

  .Card:nth-last-child(4) > .CardContainer {
    transform: scale(0.88) translateY(-12px);
  }

  .Card:nth-last-child(5) > .CardContainer {
    transform: scale(0.81) translateY(-14px);
  }

  .Card:nth-last-child(6) > .CardContainer {
    transform: scale(0.8) translateY(-14px);
  }
}
