/* .Support__Modal .Modal__close {
  display: none;
}

.progress-green {
  color: #3aba75;
}

.Support {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 480px) {
  .Support {
    height: 100%;
  }
}
.Support header h1 {
  margin: 20px 0;
}
.Support > button {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 9;
}
.Support a:not(.Button) {
  text-decoration: underline;
}
.Support header img {
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  border-radius: 3px;
}
.Support header h1 {
  font-size: 21px;
  text-align: center;
}
.Support .Support__content > p {
  font-family: "ProximaNova-RegularIt", Arial, Helvetica, sans-serif;
  margin-top: 5px;
  text-align: center;
  font-size: 15px;
}
.Support .Support__content ul {
  padding: 0;
  margin: 0;
}
.Support .Support__content ul li {
  font-size: 15px;
  line-height: 1.3em;
  margin: 15px 0;
  position: relative;
  display: flex;
  white-space: pre-wrap;
  align-items: center;
}
.Support .Support__content ul li:first-child {
  margin-top: 0;
}
.Support .Support__content ul li:last-child {
  margin-bottom: 0;
}

.Support .Support__content ul li:empty {
  display: none;
}
.Support .Support__content ul p {
  font-size: 0.75em;
  line-height: 1.3em;
  margin-bottom: 10px;
}
.Support .Support__content ul a.disabled {
  color: #2a6496;
  opacity: 0.5;
  text-decoration: none;
}
.Support .Support__content ul a.disabled:hover {
  text-decoration: none;
}
.Support .Support__content ul .Pathicon {
  align-self: flex-start;
}
.Support .Support__content ul .Support__policy {
  width: 100%;
}
.Support .Support__content ul .Support__policy p {
  color: #828282;
  margin-bottom: 10px;
  margin-top: 5px;
  font-family: "ProximaNova-RegularIt", Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 1.5em;
}
.Support .Support__content ul .Support__contact-option {
  margin-top: 10px;
}
.Support .Tooltip__trigger {
  display: inline;
}

@media screen and (max-width: 480px) {
  .Support__contact-option a {
    font-size: 18px;
    line-height: 26px;
  }
} */

.Support__container {
  padding: 0 20px;
  position: relative;
}
@media screen and (max-width: 480px) {
  .Support {
    height: 100%;
  }
}
.Support header h1 {
  margin: 20px 0;
}
.Support a:not(.Button) {
  text-decoration: underline;
}
.Support header img {
  display: block;
  margin: 0 auto;
  height: 80px;
  width: 80px;
  margin-bottom: 10px;
  border-radius: 3px;
}
.Support header h1 {
  font-size: 21px;
  text-align: center;
}
.Support .Support__content > p {
  font-family: "ProximaNova-RegularIt", Arial, Helvetica, sans-serif;
  margin-top: 5px;
  text-align: center;
  font-size: 15px;
}
.Support .Support__content ul {
  padding: 0;
  margin: 0;
}
.Support .Support__content ul li {
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 1.3em;
  margin: 15px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.Support .Support__content ul li > span:first-child {
  display: flex;
  align-items: center;
}
.Support .Support__content ul li:first-child {
  margin-top: 0;
}
.Support .Support__content ul li:last-child {
  margin-bottom: 0;
}
.Support .Support__content ul li:not(:only-child) {
  padding-left: 32px;
}
.Support .Support__content ul li:empty {
  display: none;
}
/* .Support .Support__content ul p {
  font-size: 0.75em;
  line-height: 1.3em;
  margin-bottom: 10px;
} */
.Support .Support__content ul a.disabled {
  color: #2a6496;
  opacity: 0.5;
  text-decoration: none;
}
.Support .Support__content ul a.disabled:hover {
  text-decoration: none;
}
.Support .Support__content ul .pathicon-check-circle {
  position: absolute;
  left: 0.5em;
}
.Support .Support__content ul .Support__policy {
  width: 100%;
}
/* .Support .Support__content ul .Support__policy p {
  color: #828282;
  margin-bottom: 10px;
  margin-top: 5px;
  font-family: "ProximaNova-RegularIt", Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 1.5em;
} */
.Support .Support__content ul .Support__contact-option {
  margin-top: 10px;
}
.Support .Tooltip__trigger {
  display: inline;
}

@media screen and (max-width: 480px) {
  .Support__contact-option a {
    font-size: 18px;
    line-height: 26px;
  }
}
