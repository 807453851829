.RichText .RichText__preview {
  cursor: text;
}
.RichText .RichText__preview .Text > *:last-child {
  margin-bottom: 0;
}

.RichText .FieldWrapper__field,
.RichText .FieldWrapper__input {
  max-width: 100%;
  overflow: hidden;
}

.RichText .RichText__input {
  width: 100%;
  padding: 5px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: text;
}

.RichText.RichText--empty .RichText__preview {
  color: #aaa;
}

.RichText .RichText__input {
  width: 100%;
}

.RichText.FieldWrapper--minimal .RichText__input {
  padding: 0;
  border-width: 0;
  border-radius: 0;
  border-bottom: 1px dotted #888;
  background-color: transparent;
}
.RichText.FieldWrapper--minimal .RichText__input .IconButton {
  position: absolute;
  top: 0;
  right: 0;
}

.RichText.FieldWrapper--focus .RichText__input {
  background-color: transparent;
  border-color: #6a9fcb;
  cursor: default;
}

.RichText.FieldWrapper--error .RichText__input {
  background-color: #fae5e8;
  border-color: #d0011b;
}

.RichText .IconButton {
  position: absolute;
  top: 5px;
  right: 5px;
}

.RichText .redactor-box {
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  background-color: transparent;
}

.RichText .redactor-editor {
  padding: 0;
  background-color: transparent;
  border: none;
}

.RichText .redactor-toolbar {
  z-index: 0 !important;
  margin-bottom: 5px !important;
  padding-right: 30px !important;
  box-shadow: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.RichText .redactor-placeholder:after {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.5);
}

.RichText textarea {
  padding: 0;
  border: none;
}

.RichText .RichText__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
