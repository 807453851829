.Tooltip {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: left;
  width: auto;
  z-index: 900000000000001;
  position: relative;
  /* padding: 3px 5px; */
  padding: 12px 15px;
  border-radius: 5px;
  white-space: nowrap;
  /* font-size: 10px !important;
  letter-spacing: 0.5px;
  color: #222;
  background-color: rgba(245, 245, 245, 0.8); */

  /* New tooltip styles — Aug. 2020 */
  /* background-color: rgba(255, 255, 255, 1);
  color: #000; */
  font-size: 14px !important;
  opacity: 0.9;
  line-height: 1.3;
  letter-spacing: 1px;
  background-color: #000;
  color: whitesmoke;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  /* color: rgba(255, 255, 255, 1); */
  /* font-smooth: never;
  -webkit-font-smoothing: none; */
}

.Tooltip--top:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 4px solid#000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  left: calc(50% - 4px);
  bottom: -4px;
}

.Tooltip--bottom:before {
  content: "";
  width: 0;
  height: 0;
  /* border-bottom: 4px solid rgba(245, 245, 245, 0.8); */
  opacity: 0.9;
  border-bottom: 4px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  left: calc(50% - 4px);
  top: -4px;
}

.Tooltip--left:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid #000;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  position: absolute;
  top: calc(50% - 4px);
  right: -4px;
}

.Tooltip--right:before {
  content: "";
  width: 0;
  height: 0;
  border-right: 4px solid #000;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  position: absolute;
  top: calc(50% - 4px);
  left: -4px;
}

.Tooltip a {
  color: whitesmoke;
  opacity: 0.8;
}

.Tooltip__trigger.OverlayTrigger {
  cursor: default;
}
