@charset "UTF-8";
.InvitationHeader {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.InvitationHeader__content {
  position: relative;
  width: 100%;
  padding: 20px 50px 20px;
  text-align: center;
  color: white;
}
.InvitationHeader__content .IconButton {
  position: absolute;
  top: 5px;
  left: 10px;
}
.InvitationHeader__content h2 {
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  /* white-space: nowrap; */
  white-space: pre-wrap; /* Allow for long course titles */
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
}
.InvitationHeader__content p {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.InvitationHeader__content p span {
  text-overflow: ellipsis;
}
.InvitationHeader__content p span + span:before {
  content: "•";
  margin: 0 5px;
}
.InvitationHeader__content .Link {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 480px) {
  .InvitationHeader__content {
    padding: 50px 20px 10px;
  }
  .InvitationHeader__content h2 {
    font-size: 20px;
    line-height: 24px;
  }
  .InvitationHeader__content p {
    font-size: 14px;
  }
}
