.discussion-panel-view {
  height: 100%;
  min-height: 100%;
  position: relative;
  transform: translate3d(0, 0, 0);
}
.discussion-panel-view .discussion-admin-menu {
  top: 18px;
  right: 18px;
}

/* Hide the discussion response input when creating or viewing discussion. */
.DiscussionItemContainer__create
  + .---marionette-is-rendering-this---
  .discussion-panel-view
  .discussion-response-region,
.DiscussionItemContainer__edit
  + .---marionette-is-rendering-this---
  .discussion-panel-view
  .discussion-response-region,
.DiscussionItemContainer__edit
  + .---marionette-is-rendering-this---
  .discussion-panel-view
  .discussion-empty-view {
  display: none;
}
