.progress-green {
  color: #3aba75;
}

.InvitationRow {
  position: relative;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
.InvitationRow .Avatar {
  flex-shrink: 0;
  margin-right: 10px;
}
.InvitationRow h4 {
  margin: 0;
  font-size: 16px;
}
.InvitationRow .Dropdown__Trigger {
  flex-shrink: 0;
  margin-left: 0.4em;
}

.InvitationRow__recipient {
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
}
.InvitationRow__recipient h4 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
}
.InvitationRow__recipient span {
  text-transform: capitalize;
}

.InvitationRow__status {
  flex-shrink: 0;
  overflow: hidden;
  text-align: right;
}
.InvitationRow__status:last-child {
  margin-right: 30px;
}
.InvitationRow__status h4 {
  text-transform: capitalize;
}

.InvitationRow__status--accepted {
  color: #2ecc40;
}

.InvitationRow__status--opened,
.InvitationRow__status--clicked {
  color: #007aff;
}

.InvitationRow__status--rejected,
.InvitationRow__status--deferred,
.InvitationRow__status--bounced,
.InvitationRow__status--complained,
.InvitationRow__status--unsubscribed {
  color: #ff4136;
}

.InvitationRow__loader {
  position: absolute;
  right: -20px;
}
