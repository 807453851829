.AnimatedIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AnimatedIcon > span {
  display: flex;
}

.AnimatedIcon__background {
  position: absolute;
  top: 0;
  left: 0;
}

.AnimatedIcon__border {
  position: absolute;
  top: 0;
  left: 0;
  border-width: 3px;
  border-style: solid;
}
