.FieldHeader {
  padding: 20px 25px 5px;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  color: #888;
}
.FieldHeader > *:last-child {
  margin-bottom: 0;
}
.FieldHeader h6 {
  margin: 0;
  padding: 5px 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
  color: #333;
}
