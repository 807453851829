.InviteFormMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.InviteFormMessage .Avatar {
  margin-right: 10px;
}

.InviteFormMessage__prompt {
  margin: 0;
}

.InviteFormMessage__field {
  flex-grow: 1;
}

.InviteFormMessage__cancel {
  margin-left: 5px;
}
