.Overlay {
  position: absolute;
  z-index: 9001;
}

.Overlay--place-top {
  bottom: 100%;
}

.Overlay--place-right {
  left: 100%;
}

.Overlay--place-bottom {
  top: 100%;
}

.Overlay--place-left {
  right: 100%;
}

.Overlay--align-center.Overlay--place-top,
.Overlay--align-center.Overlay--place-bottom {
  left: 50%;
  transform: translateX(-50%);
}

.Overlay--align-center.Overlay--place-right,
.Overlay--align-center.Overlay--place-left {
  top: 50%;
  transform: translateY(-50%);
}

.Overlay--align-top.Overlay--place-right,
.Overlay--align-top.Overlay--place-left {
  top: 0;
}

.Overlay--align-right.Overlay--place-top,
.Overlay--align-right.Overlay--place-bottom {
  right: 0;
}

.Overlay--align-bottom.Overlay--place-right,
.Overlay--align-bottom.Overlay--place-left {
  bottom: 0;
}

.Overlay--align-left.Overlay--place-top,
.Overlay--align-left.Overlay--place-bottom {
  left: 0;
}

.OverlayTrigger {
  cursor: pointer;
}

.OverlayTrigger.OverlayTrigger--disabled {
  pointer-events: none;
}
