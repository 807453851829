.Switch {
  width: 40px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 1px 0;
  float: right;
  background-color: #ccc;
  -webkit-user-select: none;
}

.SwitchToggle {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: white;
  position: relative;
  transition: left 0.1s ease-out;
}

.Switch--inverted.Switch--off {
  background-color: rgba(255, 255, 255, 0.3);
}

.Switch--inverted.Switch--on {
  background-color: rgba(255, 255, 255, 0.7);
}

.Switch--on {
  background: rgba(0, 0, 255, 0.5);
}

.Switch--on .SwitchToggle {
  left: 22px;
}
