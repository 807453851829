.ProgressBar {
  width: 100%;
  border-radius: 4px;
  transition: width 1s ease-in-out;
}

.ProgressBar__fill {
  height: 100%;
  border-radius: 4px;
}
