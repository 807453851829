.PaymentHistory {
  position: relative;
}
.PaymentHistory table {
  width: 100%;
  table-layout: fixed;
}
.PaymentHistory td {
  padding-right: 5px;
  white-space: nowrap;
}
.PaymentHistory td span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PaymentHistoryHeader {
  padding: 10px 25px;
}

.PaymentHistoryTable {
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 25px;
}

.PaymentHistory__item {
  display: inline-block;
  width: 60%;
}

.PaymentHistory__total {
  display: inline-block;
  width: 20%;
}

.PaymentHistory__date {
  display: inline-block;
  width: 20%;
}

.PaymentHistory__download i.pwai-download {
  font-size: 14px;
  position: relative;
  top: 1px;
}
