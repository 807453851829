.Footer {
  padding: 40px 0 20px;
  /* border-top-style: solid;
  border-top-width: 1px; */
  font-size: 16px;
  position: relative;
}
.Footer h3.brand-heading {
  color: #a1a1a1;
  margin-top: 0;
  margin-bottom: 16px;
}

.Footer__wrapper {
  display: flex;
  flex-direction: column;
  /* max-width: 1200px; */
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
@media screen and (max-width: 992px) {
  .Footer__wrapper {
    padding: 0 25px;
  }
}
@media screen and (max-width: 767px) {
  .Footer__wrapper {
    padding: 0 15px;
  }
}
.Footer__wrapper section {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .Footer__wrapper section {
    flex-direction: column;
  }
}

.Footer__left-column,
.Footer__right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
}

.Footer__left-column {
  margin-right: 20px;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .Footer__left-column {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.Footer__right-column {
  margin-left: 20px;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .Footer__right-column {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .Footer__school {
    margin-bottom: 30px;
  }
}

.Footer__school_name {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.Footer__school_description {
  margin-top: 0;
  max-width: 250px;
  font-size: 0.875em;
  font-weight: 400;
  display: block;
  line-height: 1.3em;
  color: rgba(0, 0, 0, 0.4);
}

.Footer__social {
  width: 40%;
  margin-bottom: 20px;
}
.Footer__social li {
  display: inline-block;
  padding-right: 5px;
}

.Footer__pages {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .Footer__pages {
    justify-content: flex-start;
    margin-bottom: 80px;
  }
}

.Footer__pages .Footer__pages_account {
  min-width: 160px;
}

@media screen and (max-width: 480px) {
  .Footer__pages {
    margin-bottom: 120px;
  }

  .Footer__pages .Footer__pages_account {
    min-width: 80px;
  }
}

.Footer__pages_more {
  margin-right: 24px;
}

.Footer__pages .Footer__pages_more ul {
  /* max-height: 200px; */
}

.Footer__pages .Footer__pages_more li {
  margin-right: 0.5rem;
}

.Footer__pages > div {
  display: flex;
  flex-direction: column;
}

.Footer__pages ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.Footer__pages li {
  height: 2rem;
}

.Footer__pages h5 {
  /* text-transform: uppercase;
  letter-spacing: 0.1em; */
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0.5rem;
  /* min-height: 2em */
}

.Footer__meta {
  width: 100%;
}
.Footer__meta li {
  display: inline-block;
  padding-right: 5px;
}
.Footer__meta .Button.inline {
  border-bottom: 1px solid gray;
}
@media screen and (max-width: 767px) {
  .Footer__meta {
    position: absolute;
    bottom: 4rem;
    left: 15px;
  }

  .Footer__meta li {
    display: block;
    white-space: nowrap;
  }
  .Footer__meta ul {
    display: flex;
  }
}

@media screen and (max-width: 480px) {
  .Footer__meta li.Footer__meta_separator {
    display: none;
  }
  .Footer__meta ul {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .Footer__school,
  .Footer__social,
  .Footer__pages,
  .Footer__meta {
    width: 100%;
  }
}

.Footer__powered-by {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Footer__powered-by .Button {
  margin-left: 5px;
}
