.PricingContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
}

.PricingContainer__content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.PricingLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.PricingInfo {
  color: rgb(42, 46, 51);
  border-radius: 100px;
  padding: 5px 12px;
  font-size: 16px;
  line-height: 1.2em;
  background-color: transparent;
}
.PricingInfo:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.PricingContainer__Modal .ScrollView .Modal__close {
  display: none;
}
