.ColorSwatches {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.ColorSwatches__color {
  height: 20px;
  width: 20px;
  margin: 2px;
}
