.FieldFooter {
  position: relative;
  padding: 0 25px 20px;
  line-height: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 480px) {
  .FieldFooter {
    margin-left: 0 !important;
  }
}

.FieldFooter--inverted {
  color: rgba(255, 255, 255, 0.5);
}
