.Button,
a,
button {
  position: relative;
  transition: width 0.2s ease, background-color 0.3s ease;
  max-width: 100%;
}

.ButtonLegacy,
a.ButtonLegacy,
button.ButtonLegacy {
  position: relative;
  transition: width 0.2s ease, background-color 0.3s ease;
  max-width: 100%;
  display: inline-block;
  outline: none;
  border: none;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none !important;
}

.ButtonLegacy.interactive,
a.ButtonLegacy.interactive,
button.ButtonLegacy.interactive {
  cursor: pointer;
}

.ButtonLegacy.primary,
a.ButtonLegacy.primary,
button.ButtonLegacy.primary {
  color: white;
  font-family: -apple-system, ".SFNSText-SemiBold", "San Francisco",
    "ProximaNova-Bold", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 600;
}

.ButtonLegacy.primary i,
a.ButtonLegacy.primary i,
button.ButtonLegacy.primary i {
  font-weight: 600;
}

.ButtonLegacy.primary:active,
a.ButtonLegacy.primary:active,
button.ButtonLegacy.primary:active {
  opacity: 0.8;
}

.ButtonLegacy.primary[disabled],
a.ButtonLegacy.primary[disabled],
button.ButtonLegacy.primary[disabled] {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.ButtonLegacy.primary[disabled].inverted,
a.ButtonLegacy.primary[disabled].inverted,
button.ButtonLegacy.primary[disabled].inverted {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.ButtonLegacy.primary .Pathicon,
a.ButtonLegacy.primary .Pathicon,
button.ButtonLegacy.primary .Pathicon {
  font-weight: normal;
}

.ButtonLegacy.secondary,
a.ButtonLegacy.secondary,
button.ButtonLegacy.secondary {
  background-color: rgba(0, 0, 0, 0.05);
}

.ButtonLegacy.secondary:active,
a.ButtonLegacy.secondary:active,
button.ButtonLegacy.secondary:active {
  background-color: rgba(0, 0, 0, 0.15);
}

.ButtonLegacy.default,
a.ButtonLegacy.default,
button.ButtonLegacy.default {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.ButtonLegacy.default:active,
a.ButtonLegacy.default:active,
button.ButtonLegacy.default:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.ButtonLegacy.text,
a.ButtonLegacy.text,
button.ButtonLegacy.text {
  display: inline;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.ButtonLegacy.inline,
a.ButtonLegacy.inline,
button.ButtonLegacy.inline {
  background-color: transparent;
}

.ButtonLegacy.tertiary,
a.ButtonLegacy.tertiary,
button.ButtonLegacy.tertiary {
  background-color: transparent;
}

.ButtonLegacy.tertiary:active,
a.ButtonLegacy.tertiary:active,
button.ButtonLegacy.tertiary:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.ButtonLegacy.card,
a.ButtonLegacy.card,
button.ButtonLegacy.card {
  display: block;
  background-color: transparent;
  width: 100%;
}

.ButtonLegacy.card.small,
a.ButtonLegacy.card.small,
.ButtonLegacy.card.medium,
a.ButtonLegacy.card.medium,
.ButtonLegacy.card.large,
a.ButtonLegacy.card.large,
button.ButtonLegacy.card.small,
button.ButtonLegacy.card.medium,
button.ButtonLegacy.card.large {
  padding: 15px 25px;
}

.ButtonLegacy.card.rounded,
a.ButtonLegacy.card.rounded,
button.ButtonLegacy.card.rounded {
  border-radius: 0;
}

.ButtonLegacy.card:active,
a.ButtonLegacy.card:active,
button.ButtonLegacy.card:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.ButtonLegacy.blank,
a.ButtonLegacy.blank,
button.ButtonLegacy.blank {
  background-color: transparent;
}

.ButtonLegacy.empty,
a.ButtonLegacy.empty,
button.ButtonLegacy.empty {
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.05);
}

.ButtonLegacy.empty.rounded,
a.ButtonLegacy.empty.rounded,
button.ButtonLegacy.empty.rounded {
  border-radius: 5px;
}

.ButtonLegacy.empty:active,
a.ButtonLegacy.empty:active,
button.ButtonLegacy.empty:active {
  background-color: rgba(0, 0, 0, 0.15);
}

.ButtonLegacy.empty .Pathicon,
a.ButtonLegacy.empty .Pathicon,
button.ButtonLegacy.empty .Pathicon {
  padding: 1px;
}

.ButtonLegacy.small,
a.ButtonLegacy.small,
button.ButtonLegacy.small {
  padding: 0.3em 0.8em;
  font-size: 0.9em;
  /* line-height: 1.2em; */
}

.ButtonLegacy.medium,
a.ButtonLegacy.medium,
button.ButtonLegacy.medium {
  padding: 0.4em 0.9em;
  font-size: 1em;
  /* line-height: 1.2em; */
}

.ButtonLegacy.large,
a.ButtonLegacy.large,
button.ButtonLegacy.large {
  padding: 0.5em 1em;
  font-size: 1.1em;
  /* line-height: 1.2em; */
}

.ButtonLegacy.text.small,
a.ButtonLegacy.text.small,
.ButtonLegacy.text.medium,
a.ButtonLegacy.text.medium,
.ButtonLegacy.text.large,
a.ButtonLegacy.text.large,
button.ButtonLegacy.text.small,
button.ButtonLegacy.text.medium,
button.ButtonLegacy.text.large {
  padding: 0;
  font-size: 1em;
  line-height: inherit;
}

.ButtonLegacy.inline.small,
a.ButtonLegacy.inline.small,
.ButtonLegacy.inline.medium,
a.ButtonLegacy.inline.medium,
.ButtonLegacy.inline.large,
a.ButtonLegacy.inline.large,
button.ButtonLegacy.inline.small,
button.ButtonLegacy.inline.medium,
button.ButtonLegacy.inline.large {
  padding: 0;
  font-size: 1em;
  line-height: inherit;
}

.ButtonLegacy.rounded,
a.ButtonLegacy.rounded,
button.ButtonLegacy.rounded {
  border-radius: 100px;
}

.ButtonLegacy.rounded.text,
a.ButtonLegacy.rounded.text,
button.ButtonLegacy.rounded.text {
  border-radius: 0px;
}

.ButtonLegacy.stretch,
a.ButtonLegacy.stretch,
button.ButtonLegacy.stretch {
  height: 100%;
  width: 100%;
}

.ButtonLegacy.inverted.primary,
a.ButtonLegacy.inverted.primary,
button.ButtonLegacy.inverted.primary {
  background-color: rgba(255, 255, 255, 0.95);
}

.ButtonLegacy.inverted.primary:active,
a.ButtonLegacy.inverted.primary:active,
button.ButtonLegacy.inverted.primary:active {
  background-color: rgba(255, 255, 255, 0.8);
}

.ButtonLegacy.inverted.secondary,
a.ButtonLegacy.inverted.secondary,
button.ButtonLegacy.inverted.secondary {
  color: white;
  background-color: rgba(0, 0, 0, 0.15);
}

.ButtonLegacy.inverted.secondary:active,
a.ButtonLegacy.inverted.secondary:active,
button.ButtonLegacy.inverted.secondary:active {
  background-color: rgba(0, 0, 0, 0.35);
}

.ButtonLegacy.inverted.tertiary,
a.ButtonLegacy.inverted.tertiary,
button.ButtonLegacy.inverted.tertiary {
  color: white;
}

.ButtonLegacy.inverted.tertiary:active,
a.ButtonLegacy.inverted.tertiary:active,
button.ButtonLegacy.inverted.tertiary:active {
  background-color: rgba(0, 0, 0, 0.25);
}

.ButtonLegacy.inverted.default,
a.ButtonLegacy.inverted.default,
button.ButtonLegacy.inverted.default {
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.ButtonLegacy.inverted.default:active,
a.ButtonLegacy.inverted.default:active,
button.ButtonLegacy.inverted.default:active {
  background-color: rgba(0, 0, 0, 0.25);
}

.ButtonLegacy.inverted.text,
a.ButtonLegacy.inverted.text,
button.ButtonLegacy.inverted.text {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.ButtonLegacy.inverted.inline,
a.ButtonLegacy.inverted.inline,
button.ButtonLegacy.inverted.inline {
  color: white;
}

.ButtonLegacy.inverted.blank,
a.ButtonLegacy.inverted.blank,
button.ButtonLegacy.inverted.blank {
  color: white;
}

.ButtonLegacy.inverted.empty,
a.ButtonLegacy.inverted.empty,
button.ButtonLegacy.inverted.empty {
  color: white;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.15);
}

.ButtonLegacy.inverted.empty:active,
a.ButtonLegacy.inverted.empty:active,
button.ButtonLegacy.inverted.empty:active {
  background-color: rgba(0, 0, 0, 0.35);
}

.ButtonLegacy[disabled],
a.ButtonLegacy[disabled],
button.ButtonLegacy[disabled] {
  color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: none;
  opacity: 0.7;
}

.ButtonLegacy i,
a.ButtonLegacy i,
button.ButtonLegacy i {
  position: relative;
  top: 1px;
}

.ButtonLegacy .ButtonLegacy--flex-wrapper i,
a.ButtonLegacy .ButtonLegacy--flex-wrapper i,
button.ButtonLegacy .ButtonLegacy--flex-wrapper i {
  position: relative;
  top: initial;
}

.ButtonLegacy .ButtonLegacy--flex-wrapper,
a.ButtonLegacy .ButtonLegacy--flex-wrapper,
button.ButtonLegacy .ButtonLegacy--flex-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.ButtonLegacy .CountIndicator,
a.ButtonLegacy .CountIndicator,
button.ButtonLegacy .CountIndicator {
  position: absolute;
  top: -4px;
  right: -4px;
}

.ButtonLegacy.blank .CountIndicator,
a.ButtonLegacy.blank .CountIndicator,
button.ButtonLegacy.blank .CountIndicator {
  position: absolute;
  top: 0px;
  right: 0px;
}

html.no-touch .ButtonLegacy.interactive.primary:hover,
html.no-touch a.ButtonLegacy.interactive.primary:hover,
html.no-touch button.ButtonLegacy.interactive.primary:hover {
  opacity: 0.9;
}

html.no-touch .ButtonLegacy.interactive.secondary:hover,
html.no-touch a.ButtonLegacy.interactive.secondary:hover,
html.no-touch button.ButtonLegacy.interactive.secondary:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

html.no-touch .ButtonLegacy.interactive.default:hover,
html.no-touch a.ButtonLegacy.interactive.default:hover,
html.no-touch button.ButtonLegacy.interactive.default:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

html.no-touch .ButtonLegacy.interactive.tertiary:hover,
html.no-touch a.ButtonLegacy.interactive.tertiary:hover,
html.no-touch button.ButtonLegacy.interactive.tertiary:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

html.no-touch .ButtonLegacy.interactive.card:hover,
html.no-touch a.ButtonLegacy.interactive.card:hover,
html.no-touch button.ButtonLegacy.interactive.card:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

html.no-touch .ButtonLegacy.interactive.empty:hover,
html.no-touch a.ButtonLegacy.interactive.empty:hover,
html.no-touch button.ButtonLegacy.interactive.empty:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

html.no-touch .ButtonLegacy.interactive.inverted.primary:hover,
html.no-touch a.ButtonLegacy.interactive.inverted.primary:hover,
html.no-touch button.ButtonLegacy.interactive.inverted.primary:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

html.no-touch .ButtonLegacy.interactive.inverted.secondary:hover,
html.no-touch a.ButtonLegacy.interactive.inverted.secondary:hover,
html.no-touch button.ButtonLegacy.interactive.inverted.secondary:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

html.no-touch .ButtonLegacy.interactive.inverted.tertiary:hover,
html.no-touch a.ButtonLegacy.interactive.inverted.tertiary:hover,
html.no-touch button.ButtonLegacy.interactive.inverted.tertiary:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

html.no-touch .ButtonLegacy.interactive.inverted.default:hover,
html.no-touch a.ButtonLegacy.interactive.inverted.default:hover,
html.no-touch button.ButtonLegacy.interactive.inverted.default:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

html.no-touch .ButtonLegacy.interactive.inverted.empty:hover,
html.no-touch a.ButtonLegacy.interactive.inverted.empty:hover,
html.no-touch button.ButtonLegacy.interactive.inverted.empty:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
