.Course {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Course:not(.Course--static) {
  cursor: pointer;
}

html.no-touch .Course:not(.Course--static):hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.Course__link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Course__cover,
.Course__info {
  pointer-events: none;
}

.Course__name {
  overflow: hidden;
  display: inline;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  max-height: 40px;
  text-overflow: clip;
  color: black;
  font-family: -apple-system, ".SFNSText-Bold", "San Francisco",
    "ProximaNova-Bold", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .Course__name {
    font-size: 14px;
    line-height: 16px;
    max-height: 32px;
  }
}

.Course__meta {
  margin: 0;
  white-space: nowrap;
  text-overflow: clip;
  font-size: 14px;
  line-height: 20px;
  color: #888;
}

@media screen and (max-width: 480px) {
  .Course__meta {
    line-height: 16px;
  }
}

.Course__price {
  margin: 0;
  font-size: 14px;
}

.Course--grid .Course__cover {
  position: relative;
  overflow: hidden;
  padding-top: 75%;
  width: 100%;
  height: 0;
  pointer-events: none;
}

.Course--grid .Course__cover img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.Course--grid .Course__info {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  padding: 5px 10px 10px;
  pointer-events: none;
}

.Course--grid .Course__info_wrapper {
  position: relative;
  overflow: hidden;
  width: 200%;
  margin: 0;
  line-height: 20px;
  max-height: 60px;
}

@media screen and (max-width: 480px) {
  .Course--grid .Course__info_wrapper {
    line-height: 16px;
    max-height: 48px;
  }
}

.Course--grid .Course__info_wrapper:before {
  content: "";
  display: block;
  height: 20px;
  width: 50%;
  float: right;
}

.Course--grid .Course__info_wrapper:after {
  content: "";
  position: absolute;
  top: 20px;
  right: calc(50% - 10px);
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 75%);
}

.Course--list {
  height: 100%;
  display: flex;
}

.Course--list .Course__cover {
  height: 100%;
  border-radius: 4px 0 0 4px;
}

.Course--list .Course__cover img {
  width: auto;
  height: 100%;
}

.Course--list .Course__info_wrapper {
  position: relative;
  overflow: hidden;
}

.Course--list .Course__info {
  flex-grow: 1;
  position: relative;
  padding: 10px;
  width: 50%;
}

.Course--list .Course__name {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
