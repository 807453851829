.AppPanel {
  z-index: 90000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  display: block;
  height: 100%;
  min-width: 280px;
  padding-top: 50px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: whitesmoke;
}

.Button.AppPanel__close {
  position: absolute;
  top: 2px;
  right: 0;
}

.AppPanel__auth {
  margin-top: 30px;
  margin-left: 30px;
}

@media only screen and (max-width: 280px) {
  .AppPanel {
    min-width: 100%;
  }
}
