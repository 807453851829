.InviteLink {
  flex-grow: 1;
  display: flex;
  padding: 15px 50px 50px;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 10px 10px;
}
@media screen and (max-width: 480px) {
  .InviteLink {
    padding: 15px 20px 50px;
  }
}
.InviteLink .OfferingInvitePrompt {
  padding: 5px 10px;
}
.InviteLink .OfferingInvitePrompt p {
  margin: 0;
}

.InviteLink__field {
  border: 1px solid #ddd;
  border-radius: 4px;
}
.InviteLink__field input {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  background: none;
  line-height: 25px;
  padding: 5px 10px;
  word-break: break-all;
  color: #888;
}

.InviteLink__prompt {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}

.InviteLink__prompt a {
  text-decoration: underline;
}

.InviteLink__prompt .Link {
  white-space: nowrap;
}

.InviteLink__controls {
  display: flex;
  align-items: stretch;
  margin-bottom: 5px;
}
@media screen and (max-width: 480px) {
  .InviteLink__controls .InviteLink__role span,
  .InviteLink__controls .Button {
    display: none;
  }
  .InviteLink__controls .InviteLink__role strong {
    display: block;
  }
  .InviteLink__controls .InviteLink__role strong:first-letter {
    text-transform: capitalize;
  }
}
.InviteLink__controls .Button {
  margin-left: 5px;
  border-radius: 4px !important;
}
.InviteLink__controls .SelectInput {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
