.fsp-picker {
  z-index: 99999999999999 !important;
}

.rtTooltip {
  z-index: 99999999999999 !important;
}

@media only screen and (min-width: 401px) {
  .rtTooltip {
    left: calc(50% - 200px) !important;
  }
}
