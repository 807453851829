.progress-green {
  color: #3aba75;
}

.grid-form *,
.grid-form *:before,
.grid-form *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.grid-form input[type="text"],
.grid-form input[type="email"],
.grid-form input[type="number"],
.grid-form input[type="password"],
.grid-form input[type="search"],
.grid-form input[type="tel"],
.grid-form input[type="url"],
.grid-form input[type="color"],
.grid-form input[type="date"],
.grid-form input[type="datetime"],
.grid-form input[type="datetime-local"],
.grid-form input[type="month"],
.grid-form input[type="time"],
.grid-form input[type="week"],
.grid-form textarea,
.grid-form select {
  font-size: 18px;
  padding: 0;
  margin: 0;
  width: 100%;
}

.grid-form input[type="text"],
.grid-form input[type="email"],
.grid-form input[type="number"],
.grid-form input[type="password"],
.grid-form input[type="search"],
.grid-form input[type="tel"],
.grid-form input[type="url"],
.grid-form input[type="color"],
.grid-form input[type="date"],
.grid-form input[type="datetime"],
.grid-form input[type="datetime-local"],
.grid-form input[type="month"],
.grid-form input[type="time"],
.grid-form input[type="week"],
.grid-form textarea {
  border: 0;
  background: transparent;
}
.grid-form input[type="text"]::-webkit-input-placeholder,
.grid-form input[type="email"]::-webkit-input-placeholder,
.grid-form input[type="number"]::-webkit-input-placeholder,
.grid-form input[type="password"]::-webkit-input-placeholder,
.grid-form input[type="search"]::-webkit-input-placeholder,
.grid-form input[type="tel"]::-webkit-input-placeholder,
.grid-form input[type="url"]::-webkit-input-placeholder,
.grid-form input[type="color"]::-webkit-input-placeholder,
.grid-form input[type="date"]::-webkit-input-placeholder,
.grid-form input[type="datetime"]::-webkit-input-placeholder,
.grid-form input[type="datetime-local"]::-webkit-input-placeholder,
.grid-form input[type="month"]::-webkit-input-placeholder,
.grid-form input[type="time"]::-webkit-input-placeholder,
.grid-form input[type="week"]::-webkit-input-placeholder,
.grid-form textarea::-webkit-input-placeholder {
  font-weight: 100;
  color: #595959;
}
.grid-form input[type="text"]:-moz-placeholder,
.grid-form input[type="email"]:-moz-placeholder,
.grid-form input[type="number"]:-moz-placeholder,
.grid-form input[type="password"]:-moz-placeholder,
.grid-form input[type="search"]:-moz-placeholder,
.grid-form input[type="tel"]:-moz-placeholder,
.grid-form input[type="url"]:-moz-placeholder,
.grid-form input[type="color"]:-moz-placeholder,
.grid-form input[type="date"]:-moz-placeholder,
.grid-form input[type="datetime"]:-moz-placeholder,
.grid-form input[type="datetime-local"]:-moz-placeholder,
.grid-form input[type="month"]:-moz-placeholder,
.grid-form input[type="time"]:-moz-placeholder,
.grid-form input[type="week"]:-moz-placeholder,
.grid-form textarea:-moz-placeholder {
  font-weight: 100;
  color: #595959;
}
.grid-form input[type="text"]:focus,
.grid-form input[type="email"]:focus,
.grid-form input[type="number"]:focus,
.grid-form input[type="password"]:focus,
.grid-form input[type="search"]:focus,
.grid-form input[type="tel"]:focus,
.grid-form input[type="url"]:focus,
.grid-form input[type="color"]:focus,
.grid-form input[type="date"]:focus,
.grid-form input[type="datetime"]:focus,
.grid-form input[type="datetime-local"]:focus,
.grid-form input[type="month"]:focus,
.grid-form input[type="time"]:focus,
.grid-form input[type="week"]:focus,
.grid-form textarea:focus {
  outline: none;
}

.grid-form fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.grid-form fieldset legend {
  border: none;
  border-bottom: 4px solid #404040;
  color: #404040;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
  position: static;
  width: 100%;
}
.grid-form fieldset fieldset legend {
  border-bottom: 2px solid #404040;
  font-weight: normal;
}
.grid-form fieldset fieldset fieldset legend {
  border-bottom: 1px solid #404040;
  font-weight: normal;
  font-size: 15px;
}

.grid-form [data-row-span] {
  border-bottom: 1px solid #333333;
  width: 100%;
  zoom: 1;
}
.grid-form [data-row-span]:before,
.grid-form [data-row-span]:after {
  content: "";
  display: table;
}
.grid-form [data-row-span]:after {
  clear: both;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .grid-form [data-row-span] {
    border-bottom: none;
  }
}
.grid-form [data-row-span] [data-field-span] {
  padding: 8px;
  float: left;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .grid-form [data-row-span] [data-field-span] {
    border-bottom: 1px solid #333333;
    width: 100% !important;
  }
}
@media only screen and (min-width: 700px) {
  .grid-form [data-row-span] [data-field-span] {
    border-right: 1px solid #333333;
    display: block;
  }
}
.grid-form [data-row-span] [data-field-span] > label:first-of-type,
.grid-form [data-row-span] [data-field-span] label.grid-form-label {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #333333;
  display: block;
  margin-bottom: 10px;
  padding-top: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.grid-form [data-row-span] [data-field-span] > label:first-of-type:hover,
.grid-form [data-row-span] [data-field-span] label.grid-form-label:hover {
  cursor: text;
}
.grid-form [data-row-span] [data-field-span]:last-child {
  border-right: none;
}
.grid-form [data-row-span] [data-field-span].focus {
  background: #fffad4;
}
.grid-form [data-row-span] [data-field-span]:hover {
  background: #fffad4;
  cursor: text;
}

.grid-form [data-row-span="1"] > [data-field-span="1"] {
  width: 100%;
}

.grid-form [data-row-span="2"] > [data-field-span="1"] {
  width: 50%;
}

.grid-form [data-row-span="2"] > [data-field-span="2"] {
  width: 100%;
}

.grid-form [data-row-span="3"] > [data-field-span="1"] {
  width: 33.33333%;
}

.grid-form [data-row-span="3"] > [data-field-span="2"] {
  width: 66.66667%;
}

.grid-form [data-row-span="3"] > [data-field-span="3"] {
  width: 100%;
}

.grid-form [data-row-span="4"] > [data-field-span="1"] {
  width: 25%;
}

.grid-form [data-row-span="4"] > [data-field-span="2"] {
  width: 50%;
}

.grid-form [data-row-span="4"] > [data-field-span="3"] {
  width: 75%;
}

.grid-form [data-row-span="4"] > [data-field-span="4"] {
  width: 100%;
}

.grid-form [data-row-span="5"] > [data-field-span="1"] {
  width: 20%;
}

.grid-form [data-row-span="5"] > [data-field-span="2"] {
  width: 40%;
}

.grid-form [data-row-span="5"] > [data-field-span="3"] {
  width: 60%;
}

.grid-form [data-row-span="5"] > [data-field-span="4"] {
  width: 80%;
}

.grid-form [data-row-span="5"] > [data-field-span="5"] {
  width: 100%;
}

.grid-form [data-row-span="6"] > [data-field-span="1"] {
  width: 16.66667%;
}

.grid-form [data-row-span="6"] > [data-field-span="2"] {
  width: 33.33333%;
}

.grid-form [data-row-span="6"] > [data-field-span="3"] {
  width: 50%;
}

.grid-form [data-row-span="6"] > [data-field-span="4"] {
  width: 66.66667%;
}

.grid-form [data-row-span="6"] > [data-field-span="5"] {
  width: 83.33333%;
}

.grid-form [data-row-span="6"] > [data-field-span="6"] {
  width: 100%;
}

.grid-form [data-row-span="7"] > [data-field-span="1"] {
  width: 14.28571%;
}

.grid-form [data-row-span="7"] > [data-field-span="2"] {
  width: 28.57143%;
}

.grid-form [data-row-span="7"] > [data-field-span="3"] {
  width: 42.85714%;
}

.grid-form [data-row-span="7"] > [data-field-span="4"] {
  width: 57.14286%;
}

.grid-form [data-row-span="7"] > [data-field-span="5"] {
  width: 71.42857%;
}

.grid-form [data-row-span="7"] > [data-field-span="6"] {
  width: 85.71429%;
}

.grid-form [data-row-span="7"] > [data-field-span="7"] {
  width: 100%;
}

.grid-form [data-row-span="8"] > [data-field-span="1"] {
  width: 12.5%;
}

.grid-form [data-row-span="8"] > [data-field-span="2"] {
  width: 25%;
}

.grid-form [data-row-span="8"] > [data-field-span="3"] {
  width: 37.5%;
}

.grid-form [data-row-span="8"] > [data-field-span="4"] {
  width: 50%;
}

.grid-form [data-row-span="8"] > [data-field-span="5"] {
  width: 62.5%;
}

.grid-form [data-row-span="8"] > [data-field-span="6"] {
  width: 75%;
}

.grid-form [data-row-span="8"] > [data-field-span="7"] {
  width: 87.5%;
}

.grid-form [data-row-span="8"] > [data-field-span="8"] {
  width: 100%;
}

.grid-form [data-row-span="9"] > [data-field-span="1"] {
  width: 11.11111%;
}

.grid-form [data-row-span="9"] > [data-field-span="2"] {
  width: 22.22222%;
}

.grid-form [data-row-span="9"] > [data-field-span="3"] {
  width: 33.33333%;
}

.grid-form [data-row-span="9"] > [data-field-span="4"] {
  width: 44.44444%;
}

.grid-form [data-row-span="9"] > [data-field-span="5"] {
  width: 55.55556%;
}

.grid-form [data-row-span="9"] > [data-field-span="6"] {
  width: 66.66667%;
}

.grid-form [data-row-span="9"] > [data-field-span="7"] {
  width: 77.77778%;
}

.grid-form [data-row-span="9"] > [data-field-span="8"] {
  width: 88.88889%;
}

.grid-form [data-row-span="9"] > [data-field-span="9"] {
  width: 100%;
}

.grid-form [data-row-span="10"] > [data-field-span="1"] {
  width: 10%;
}

.grid-form [data-row-span="10"] > [data-field-span="2"] {
  width: 20%;
}

.grid-form [data-row-span="10"] > [data-field-span="3"] {
  width: 30%;
}

.grid-form [data-row-span="10"] > [data-field-span="4"] {
  width: 40%;
}

.grid-form [data-row-span="10"] > [data-field-span="5"] {
  width: 50%;
}

.grid-form [data-row-span="10"] > [data-field-span="6"] {
  width: 60%;
}

.grid-form [data-row-span="10"] > [data-field-span="7"] {
  width: 70%;
}

.grid-form [data-row-span="10"] > [data-field-span="8"] {
  width: 80%;
}

.grid-form [data-row-span="10"] > [data-field-span="9"] {
  width: 90%;
}

.grid-form [data-row-span="10"] > [data-field-span="10"] {
  width: 100%;
}

.grid-form [data-row-span="11"] > [data-field-span="1"] {
  width: 9.09091%;
}

.grid-form [data-row-span="11"] > [data-field-span="2"] {
  width: 18.18182%;
}

.grid-form [data-row-span="11"] > [data-field-span="3"] {
  width: 27.27273%;
}

.grid-form [data-row-span="11"] > [data-field-span="4"] {
  width: 36.36364%;
}

.grid-form [data-row-span="11"] > [data-field-span="5"] {
  width: 45.45455%;
}

.grid-form [data-row-span="11"] > [data-field-span="6"] {
  width: 54.54545%;
}

.grid-form [data-row-span="11"] > [data-field-span="7"] {
  width: 63.63636%;
}

.grid-form [data-row-span="11"] > [data-field-span="8"] {
  width: 72.72727%;
}

.grid-form [data-row-span="11"] > [data-field-span="9"] {
  width: 81.81818%;
}

.grid-form [data-row-span="11"] > [data-field-span="10"] {
  width: 90.90909%;
}

.grid-form [data-row-span="11"] > [data-field-span="11"] {
  width: 100%;
}

.grid-form [data-row-span="12"] > [data-field-span="1"] {
  width: 8.33333%;
}

.grid-form [data-row-span="12"] > [data-field-span="2"] {
  width: 16.66667%;
}

.grid-form [data-row-span="12"] > [data-field-span="3"] {
  width: 25%;
}

.grid-form [data-row-span="12"] > [data-field-span="4"] {
  width: 33.33333%;
}

.grid-form [data-row-span="12"] > [data-field-span="5"] {
  width: 41.66667%;
}

.grid-form [data-row-span="12"] > [data-field-span="6"] {
  width: 50%;
}

.grid-form [data-row-span="12"] > [data-field-span="7"] {
  width: 58.33333%;
}

.grid-form [data-row-span="12"] > [data-field-span="8"] {
  width: 66.66667%;
}

.grid-form [data-row-span="12"] > [data-field-span="9"] {
  width: 75%;
}

.grid-form [data-row-span="12"] > [data-field-span="10"] {
  width: 83.33333%;
}

.grid-form [data-row-span="12"] > [data-field-span="11"] {
  width: 91.66667%;
}

.grid-form [data-row-span="12"] > [data-field-span="12"] {
  width: 100%;
}

.pw-uniform {
  text-align: left;
}
.pw-uniform *,
.pw-uniform *:before,
.pw-uniform *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.pw-uniform input[type="text"],
.pw-uniform input[type="email"],
.pw-uniform input[type="number"],
.pw-uniform input[type="password"],
.pw-uniform input[type="search"],
.pw-uniform input[type="tel"],
.pw-uniform input[type="url"],
.pw-uniform input[type="color"],
.pw-uniform input[type="date"],
.pw-uniform input[type="datetime"],
.pw-uniform input[type="datetime-local"],
.pw-uniform input[type="month"],
.pw-uniform input[type="time"],
.pw-uniform input[type="week"],
.pw-uniform textarea,
.pw-uniform select {
  font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100%;
}
.pw-uniform input[type="text"],
.pw-uniform input[type="email"],
.pw-uniform input[type="number"],
.pw-uniform input[type="password"],
.pw-uniform input[type="search"],
.pw-uniform input[type="tel"],
.pw-uniform input[type="url"],
.pw-uniform input[type="color"],
.pw-uniform input[type="date"],
.pw-uniform input[type="datetime"],
.pw-uniform input[type="datetime-local"],
.pw-uniform input[type="month"],
.pw-uniform input[type="time"],
.pw-uniform input[type="week"],
.pw-uniform textarea {
  border: 0;
  background: transparent;
}
.pw-uniform input[type="text"]::-webkit-input-placeholder,
.pw-uniform input[type="email"]::-webkit-input-placeholder,
.pw-uniform input[type="number"]::-webkit-input-placeholder,
.pw-uniform input[type="password"]::-webkit-input-placeholder,
.pw-uniform input[type="search"]::-webkit-input-placeholder,
.pw-uniform input[type="tel"]::-webkit-input-placeholder,
.pw-uniform input[type="url"]::-webkit-input-placeholder,
.pw-uniform input[type="color"]::-webkit-input-placeholder,
.pw-uniform input[type="date"]::-webkit-input-placeholder,
.pw-uniform input[type="datetime"]::-webkit-input-placeholder,
.pw-uniform input[type="datetime-local"]::-webkit-input-placeholder,
.pw-uniform input[type="month"]::-webkit-input-placeholder,
.pw-uniform input[type="time"]::-webkit-input-placeholder,
.pw-uniform input[type="week"]::-webkit-input-placeholder,
.pw-uniform textarea::-webkit-input-placeholder {
  font-weight: 100;
  color: #676767;
}
.pw-uniform input[type="text"]:-moz-placeholder,
.pw-uniform input[type="email"]:-moz-placeholder,
.pw-uniform input[type="number"]:-moz-placeholder,
.pw-uniform input[type="password"]:-moz-placeholder,
.pw-uniform input[type="search"]:-moz-placeholder,
.pw-uniform input[type="tel"]:-moz-placeholder,
.pw-uniform input[type="url"]:-moz-placeholder,
.pw-uniform input[type="color"]:-moz-placeholder,
.pw-uniform input[type="date"]:-moz-placeholder,
.pw-uniform input[type="datetime"]:-moz-placeholder,
.pw-uniform input[type="datetime-local"]:-moz-placeholder,
.pw-uniform input[type="month"]:-moz-placeholder,
.pw-uniform input[type="time"]:-moz-placeholder,
.pw-uniform input[type="week"]:-moz-placeholder,
.pw-uniform textarea:-moz-placeholder {
  font-weight: 100;
  color: #676767;
}
.pw-uniform input[type="text"]:focus,
.pw-uniform input[type="email"]:focus,
.pw-uniform input[type="number"]:focus,
.pw-uniform input[type="password"]:focus,
.pw-uniform input[type="search"]:focus,
.pw-uniform input[type="tel"]:focus,
.pw-uniform input[type="url"]:focus,
.pw-uniform input[type="color"]:focus,
.pw-uniform input[type="date"]:focus,
.pw-uniform input[type="datetime"]:focus,
.pw-uniform input[type="datetime-local"]:focus,
.pw-uniform input[type="month"]:focus,
.pw-uniform input[type="time"]:focus,
.pw-uniform input[type="week"]:focus,
.pw-uniform textarea:focus {
  outline: none;
}
.pw-uniform fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.pw-uniform fieldset legend {
  border: none;
  border-bottom: 4px solid #ababab;
  color: #ababab;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  position: static;
  width: 100%;
}
.pw-uniform fieldset fieldset legend {
  border-bottom: 2px solid #ababab;
  font-weight: normal;
}
.pw-uniform fieldset fieldset fieldset legend {
  border-bottom: 1px solid #ababab;
  font-weight: normal;
  font-size: 13px;
}
.pw-uniform [data-row-span] {
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  zoom: 1;
}
.pw-uniform [data-row-span]:before,
.pw-uniform [data-row-span]:after {
  content: "";
  display: table;
}
.pw-uniform [data-row-span]:after {
  clear: both;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .pw-uniform [data-row-span] {
    border-bottom: none;
  }
}
.pw-uniform [data-row-span] [data-field-span] {
  padding: 8px;
  float: left;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .pw-uniform [data-row-span] [data-field-span] {
    border-bottom: 1px solid #d0d0d0;
    width: 100% !important;
  }
}
@media only screen and (min-width: 700px) {
  .pw-uniform [data-row-span] [data-field-span] {
    border-right: 1px solid #d0d0d0;
    display: block;
  }
}
.pw-uniform [data-row-span] [data-field-span] > label:first-of-type,
.pw-uniform [data-row-span] [data-field-span] label.grid-form-label {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #414141;
  display: block;
  margin-bottom: 10px;
  padding-top: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pw-uniform [data-row-span] [data-field-span] > label:first-of-type:hover,
.pw-uniform [data-row-span] [data-field-span] label.grid-form-label:hover {
  cursor: text;
}
.pw-uniform [data-row-span] [data-field-span]:last-child {
  border-right: none;
}
.pw-uniform [data-row-span] [data-field-span].focus {
  background: #f9fbff;
}
.pw-uniform [data-row-span] [data-field-span]:hover {
  background: #f9fbff;
  cursor: text;
}
.pw-uniform [data-row-span="1"] > [data-field-span="1"] {
  width: 100%;
}
.pw-uniform [data-row-span="2"] > [data-field-span="1"] {
  width: 50%;
}
.pw-uniform [data-row-span="2"] > [data-field-span="2"] {
  width: 100%;
}
.pw-uniform [data-row-span="3"] > [data-field-span="1"] {
  width: 33.33333%;
}
.pw-uniform [data-row-span="3"] > [data-field-span="2"] {
  width: 66.66667%;
}
.pw-uniform [data-row-span="3"] > [data-field-span="3"] {
  width: 100%;
}
.pw-uniform [data-row-span="4"] > [data-field-span="1"] {
  width: 25%;
}
.pw-uniform [data-row-span="4"] > [data-field-span="2"] {
  width: 50%;
}
.pw-uniform [data-row-span="4"] > [data-field-span="3"] {
  width: 75%;
}
.pw-uniform [data-row-span="4"] > [data-field-span="4"] {
  width: 100%;
}
.pw-uniform [data-row-span="5"] > [data-field-span="1"] {
  width: 20%;
}
.pw-uniform [data-row-span="5"] > [data-field-span="2"] {
  width: 40%;
}
.pw-uniform [data-row-span="5"] > [data-field-span="3"] {
  width: 60%;
}
.pw-uniform [data-row-span="5"] > [data-field-span="4"] {
  width: 80%;
}
.pw-uniform [data-row-span="5"] > [data-field-span="5"] {
  width: 100%;
}
.pw-uniform [data-row-span="6"] > [data-field-span="1"] {
  width: 16.66667%;
}
.pw-uniform [data-row-span="6"] > [data-field-span="2"] {
  width: 33.33333%;
}
.pw-uniform [data-row-span="6"] > [data-field-span="3"] {
  width: 50%;
}
.pw-uniform [data-row-span="6"] > [data-field-span="4"] {
  width: 66.66667%;
}
.pw-uniform [data-row-span="6"] > [data-field-span="5"] {
  width: 83.33333%;
}
.pw-uniform [data-row-span="6"] > [data-field-span="6"] {
  width: 100%;
}
.pw-uniform [data-row-span="7"] > [data-field-span="1"] {
  width: 14.28571%;
}
.pw-uniform [data-row-span="7"] > [data-field-span="2"] {
  width: 28.57143%;
}
.pw-uniform [data-row-span="7"] > [data-field-span="3"] {
  width: 42.85714%;
}
.pw-uniform [data-row-span="7"] > [data-field-span="4"] {
  width: 57.14286%;
}
.pw-uniform [data-row-span="7"] > [data-field-span="5"] {
  width: 71.42857%;
}
.pw-uniform [data-row-span="7"] > [data-field-span="6"] {
  width: 85.71429%;
}
.pw-uniform [data-row-span="7"] > [data-field-span="7"] {
  width: 100%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="1"] {
  width: 12.5%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="2"] {
  width: 25%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="3"] {
  width: 37.5%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="4"] {
  width: 50%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="5"] {
  width: 62.5%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="6"] {
  width: 75%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="7"] {
  width: 87.5%;
}
.pw-uniform [data-row-span="8"] > [data-field-span="8"] {
  width: 100%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="1"] {
  width: 11.11111%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="2"] {
  width: 22.22222%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="3"] {
  width: 33.33333%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="4"] {
  width: 44.44444%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="5"] {
  width: 55.55556%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="6"] {
  width: 66.66667%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="7"] {
  width: 77.77778%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="8"] {
  width: 88.88889%;
}
.pw-uniform [data-row-span="9"] > [data-field-span="9"] {
  width: 100%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="1"] {
  width: 10%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="2"] {
  width: 20%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="3"] {
  width: 30%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="4"] {
  width: 40%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="5"] {
  width: 50%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="6"] {
  width: 60%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="7"] {
  width: 70%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="8"] {
  width: 80%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="9"] {
  width: 90%;
}
.pw-uniform [data-row-span="10"] > [data-field-span="10"] {
  width: 100%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="1"] {
  width: 9.09091%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="2"] {
  width: 18.18182%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="3"] {
  width: 27.27273%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="4"] {
  width: 36.36364%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="5"] {
  width: 45.45455%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="6"] {
  width: 54.54545%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="7"] {
  width: 63.63636%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="8"] {
  width: 72.72727%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="9"] {
  width: 81.81818%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="10"] {
  width: 90.90909%;
}
.pw-uniform [data-row-span="11"] > [data-field-span="11"] {
  width: 100%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="1"] {
  width: 8.33333%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="2"] {
  width: 16.66667%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="3"] {
  width: 25%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="4"] {
  width: 33.33333%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="5"] {
  width: 41.66667%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="6"] {
  width: 50%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="7"] {
  width: 58.33333%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="8"] {
  width: 66.66667%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="9"] {
  width: 75%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="10"] {
  width: 83.33333%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="11"] {
  width: 91.66667%;
}
.pw-uniform [data-row-span="12"] > [data-field-span="12"] {
  width: 100%;
}
.pw-uniform.white-focus *,
.pw-uniform.white-focus *:before,
.pw-uniform.white-focus *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.pw-uniform.white-focus input[type="text"],
.pw-uniform.white-focus input[type="email"],
.pw-uniform.white-focus input[type="number"],
.pw-uniform.white-focus input[type="password"],
.pw-uniform.white-focus input[type="search"],
.pw-uniform.white-focus input[type="tel"],
.pw-uniform.white-focus input[type="url"],
.pw-uniform.white-focus input[type="color"],
.pw-uniform.white-focus input[type="date"],
.pw-uniform.white-focus input[type="datetime"],
.pw-uniform.white-focus input[type="datetime-local"],
.pw-uniform.white-focus input[type="month"],
.pw-uniform.white-focus input[type="time"],
.pw-uniform.white-focus input[type="week"],
.pw-uniform.white-focus textarea,
.pw-uniform.white-focus select {
  font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100%;
}
.pw-uniform.white-focus input[type="text"],
.pw-uniform.white-focus input[type="email"],
.pw-uniform.white-focus input[type="number"],
.pw-uniform.white-focus input[type="password"],
.pw-uniform.white-focus input[type="search"],
.pw-uniform.white-focus input[type="tel"],
.pw-uniform.white-focus input[type="url"],
.pw-uniform.white-focus input[type="color"],
.pw-uniform.white-focus input[type="date"],
.pw-uniform.white-focus input[type="datetime"],
.pw-uniform.white-focus input[type="datetime-local"],
.pw-uniform.white-focus input[type="month"],
.pw-uniform.white-focus input[type="time"],
.pw-uniform.white-focus input[type="week"],
.pw-uniform.white-focus textarea {
  border: 0;
  background: transparent;
}
.pw-uniform.white-focus input[type="text"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="email"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="number"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="password"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="search"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="tel"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="url"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="color"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="date"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="datetime"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="datetime-local"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="month"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="time"]::-webkit-input-placeholder,
.pw-uniform.white-focus input[type="week"]::-webkit-input-placeholder,
.pw-uniform.white-focus textarea::-webkit-input-placeholder {
  font-weight: 100;
  color: #676767;
}
.pw-uniform.white-focus input[type="text"]:-moz-placeholder,
.pw-uniform.white-focus input[type="email"]:-moz-placeholder,
.pw-uniform.white-focus input[type="number"]:-moz-placeholder,
.pw-uniform.white-focus input[type="password"]:-moz-placeholder,
.pw-uniform.white-focus input[type="search"]:-moz-placeholder,
.pw-uniform.white-focus input[type="tel"]:-moz-placeholder,
.pw-uniform.white-focus input[type="url"]:-moz-placeholder,
.pw-uniform.white-focus input[type="color"]:-moz-placeholder,
.pw-uniform.white-focus input[type="date"]:-moz-placeholder,
.pw-uniform.white-focus input[type="datetime"]:-moz-placeholder,
.pw-uniform.white-focus input[type="datetime-local"]:-moz-placeholder,
.pw-uniform.white-focus input[type="month"]:-moz-placeholder,
.pw-uniform.white-focus input[type="time"]:-moz-placeholder,
.pw-uniform.white-focus input[type="week"]:-moz-placeholder,
.pw-uniform.white-focus textarea:-moz-placeholder {
  font-weight: 100;
  color: #676767;
}
.pw-uniform.white-focus input[type="text"]:focus,
.pw-uniform.white-focus input[type="email"]:focus,
.pw-uniform.white-focus input[type="number"]:focus,
.pw-uniform.white-focus input[type="password"]:focus,
.pw-uniform.white-focus input[type="search"]:focus,
.pw-uniform.white-focus input[type="tel"]:focus,
.pw-uniform.white-focus input[type="url"]:focus,
.pw-uniform.white-focus input[type="color"]:focus,
.pw-uniform.white-focus input[type="date"]:focus,
.pw-uniform.white-focus input[type="datetime"]:focus,
.pw-uniform.white-focus input[type="datetime-local"]:focus,
.pw-uniform.white-focus input[type="month"]:focus,
.pw-uniform.white-focus input[type="time"]:focus,
.pw-uniform.white-focus input[type="week"]:focus,
.pw-uniform.white-focus textarea:focus {
  outline: none;
}
.pw-uniform.white-focus fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.pw-uniform.white-focus fieldset legend {
  border: none;
  border-bottom: 4px solid #ababab;
  color: #ababab;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  position: static;
  width: 100%;
}
.pw-uniform.white-focus fieldset fieldset legend {
  border-bottom: 2px solid #ababab;
  font-weight: normal;
}
.pw-uniform.white-focus fieldset fieldset fieldset legend {
  border-bottom: 1px solid #ababab;
  font-weight: normal;
  font-size: 13px;
}
.pw-uniform.white-focus [data-row-span] {
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  zoom: 1;
}
.pw-uniform.white-focus [data-row-span]:before,
.pw-uniform.white-focus [data-row-span]:after {
  content: "";
  display: table;
}
.pw-uniform.white-focus [data-row-span]:after {
  clear: both;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .pw-uniform.white-focus [data-row-span] {
    border-bottom: none;
  }
}
.pw-uniform.white-focus [data-row-span] [data-field-span] {
  padding: 8px;
  float: left;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .pw-uniform.white-focus [data-row-span] [data-field-span] {
    border-bottom: 1px solid #d0d0d0;
    width: 100% !important;
  }
}
@media only screen and (min-width: 700px) {
  .pw-uniform.white-focus [data-row-span] [data-field-span] {
    border-right: 1px solid #d0d0d0;
    display: block;
  }
}
.pw-uniform.white-focus [data-row-span] [data-field-span] > label:first-of-type,
.pw-uniform.white-focus
  [data-row-span]
  [data-field-span]
  label.grid-form-label {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #414141;
  display: block;
  margin-bottom: 10px;
  padding-top: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pw-uniform.white-focus
  [data-row-span]
  [data-field-span]
  > label:first-of-type:hover,
.pw-uniform.white-focus
  [data-row-span]
  [data-field-span]
  label.grid-form-label:hover {
  cursor: text;
}
.pw-uniform.white-focus [data-row-span] [data-field-span]:last-child {
  border-right: none;
}
.pw-uniform.white-focus [data-row-span] [data-field-span].focus {
  background: #fff;
}
.pw-uniform.white-focus [data-row-span] [data-field-span]:hover {
  background: #fff;
  cursor: text;
}
.pw-uniform.white-focus [data-row-span="1"] > [data-field-span="1"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="2"] > [data-field-span="1"] {
  width: 50%;
}
.pw-uniform.white-focus [data-row-span="2"] > [data-field-span="2"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="3"] > [data-field-span="1"] {
  width: 33.33333%;
}
.pw-uniform.white-focus [data-row-span="3"] > [data-field-span="2"] {
  width: 66.66667%;
}
.pw-uniform.white-focus [data-row-span="3"] > [data-field-span="3"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="4"] > [data-field-span="1"] {
  width: 25%;
}
.pw-uniform.white-focus [data-row-span="4"] > [data-field-span="2"] {
  width: 50%;
}
.pw-uniform.white-focus [data-row-span="4"] > [data-field-span="3"] {
  width: 75%;
}
.pw-uniform.white-focus [data-row-span="4"] > [data-field-span="4"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="5"] > [data-field-span="1"] {
  width: 20%;
}
.pw-uniform.white-focus [data-row-span="5"] > [data-field-span="2"] {
  width: 40%;
}
.pw-uniform.white-focus [data-row-span="5"] > [data-field-span="3"] {
  width: 60%;
}
.pw-uniform.white-focus [data-row-span="5"] > [data-field-span="4"] {
  width: 80%;
}
.pw-uniform.white-focus [data-row-span="5"] > [data-field-span="5"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="6"] > [data-field-span="1"] {
  width: 16.66667%;
}
.pw-uniform.white-focus [data-row-span="6"] > [data-field-span="2"] {
  width: 33.33333%;
}
.pw-uniform.white-focus [data-row-span="6"] > [data-field-span="3"] {
  width: 50%;
}
.pw-uniform.white-focus [data-row-span="6"] > [data-field-span="4"] {
  width: 66.66667%;
}
.pw-uniform.white-focus [data-row-span="6"] > [data-field-span="5"] {
  width: 83.33333%;
}
.pw-uniform.white-focus [data-row-span="6"] > [data-field-span="6"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="7"] > [data-field-span="1"] {
  width: 14.28571%;
}
.pw-uniform.white-focus [data-row-span="7"] > [data-field-span="2"] {
  width: 28.57143%;
}
.pw-uniform.white-focus [data-row-span="7"] > [data-field-span="3"] {
  width: 42.85714%;
}
.pw-uniform.white-focus [data-row-span="7"] > [data-field-span="4"] {
  width: 57.14286%;
}
.pw-uniform.white-focus [data-row-span="7"] > [data-field-span="5"] {
  width: 71.42857%;
}
.pw-uniform.white-focus [data-row-span="7"] > [data-field-span="6"] {
  width: 85.71429%;
}
.pw-uniform.white-focus [data-row-span="7"] > [data-field-span="7"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="1"] {
  width: 12.5%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="2"] {
  width: 25%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="3"] {
  width: 37.5%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="4"] {
  width: 50%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="5"] {
  width: 62.5%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="6"] {
  width: 75%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="7"] {
  width: 87.5%;
}
.pw-uniform.white-focus [data-row-span="8"] > [data-field-span="8"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="1"] {
  width: 11.11111%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="2"] {
  width: 22.22222%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="3"] {
  width: 33.33333%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="4"] {
  width: 44.44444%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="5"] {
  width: 55.55556%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="6"] {
  width: 66.66667%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="7"] {
  width: 77.77778%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="8"] {
  width: 88.88889%;
}
.pw-uniform.white-focus [data-row-span="9"] > [data-field-span="9"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="1"] {
  width: 10%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="2"] {
  width: 20%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="3"] {
  width: 30%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="4"] {
  width: 40%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="5"] {
  width: 50%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="6"] {
  width: 60%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="7"] {
  width: 70%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="8"] {
  width: 80%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="9"] {
  width: 90%;
}
.pw-uniform.white-focus [data-row-span="10"] > [data-field-span="10"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="1"] {
  width: 9.09091%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="2"] {
  width: 18.18182%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="3"] {
  width: 27.27273%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="4"] {
  width: 36.36364%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="5"] {
  width: 45.45455%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="6"] {
  width: 54.54545%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="7"] {
  width: 63.63636%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="8"] {
  width: 72.72727%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="9"] {
  width: 81.81818%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="10"] {
  width: 90.90909%;
}
.pw-uniform.white-focus [data-row-span="11"] > [data-field-span="11"] {
  width: 100%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="1"] {
  width: 8.33333%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="2"] {
  width: 16.66667%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="3"] {
  width: 25%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="4"] {
  width: 33.33333%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="5"] {
  width: 41.66667%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="6"] {
  width: 50%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="7"] {
  width: 58.33333%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="8"] {
  width: 66.66667%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="9"] {
  width: 75%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="10"] {
  width: 83.33333%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="11"] {
  width: 91.66667%;
}
.pw-uniform.white-focus [data-row-span="12"] > [data-field-span="12"] {
  width: 100%;
}
.pw-uniform.no-focus *,
.pw-uniform.no-focus *:before,
.pw-uniform.no-focus *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.pw-uniform.no-focus input[type="text"],
.pw-uniform.no-focus input[type="email"],
.pw-uniform.no-focus input[type="number"],
.pw-uniform.no-focus input[type="password"],
.pw-uniform.no-focus input[type="search"],
.pw-uniform.no-focus input[type="tel"],
.pw-uniform.no-focus input[type="url"],
.pw-uniform.no-focus input[type="color"],
.pw-uniform.no-focus input[type="date"],
.pw-uniform.no-focus input[type="datetime"],
.pw-uniform.no-focus input[type="datetime-local"],
.pw-uniform.no-focus input[type="month"],
.pw-uniform.no-focus input[type="time"],
.pw-uniform.no-focus input[type="week"],
.pw-uniform.no-focus textarea,
.pw-uniform.no-focus select {
  font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100%;
}
.pw-uniform.no-focus input[type="text"],
.pw-uniform.no-focus input[type="email"],
.pw-uniform.no-focus input[type="number"],
.pw-uniform.no-focus input[type="password"],
.pw-uniform.no-focus input[type="search"],
.pw-uniform.no-focus input[type="tel"],
.pw-uniform.no-focus input[type="url"],
.pw-uniform.no-focus input[type="color"],
.pw-uniform.no-focus input[type="date"],
.pw-uniform.no-focus input[type="datetime"],
.pw-uniform.no-focus input[type="datetime-local"],
.pw-uniform.no-focus input[type="month"],
.pw-uniform.no-focus input[type="time"],
.pw-uniform.no-focus input[type="week"],
.pw-uniform.no-focus textarea {
  border: 0;
  background: transparent;
}
.pw-uniform.no-focus input[type="text"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="email"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="number"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="password"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="search"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="tel"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="url"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="color"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="date"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="datetime"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="datetime-local"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="month"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="time"]::-webkit-input-placeholder,
.pw-uniform.no-focus input[type="week"]::-webkit-input-placeholder,
.pw-uniform.no-focus textarea::-webkit-input-placeholder {
  font-weight: 100;
  color: #676767;
}
.pw-uniform.no-focus input[type="text"]:-moz-placeholder,
.pw-uniform.no-focus input[type="email"]:-moz-placeholder,
.pw-uniform.no-focus input[type="number"]:-moz-placeholder,
.pw-uniform.no-focus input[type="password"]:-moz-placeholder,
.pw-uniform.no-focus input[type="search"]:-moz-placeholder,
.pw-uniform.no-focus input[type="tel"]:-moz-placeholder,
.pw-uniform.no-focus input[type="url"]:-moz-placeholder,
.pw-uniform.no-focus input[type="color"]:-moz-placeholder,
.pw-uniform.no-focus input[type="date"]:-moz-placeholder,
.pw-uniform.no-focus input[type="datetime"]:-moz-placeholder,
.pw-uniform.no-focus input[type="datetime-local"]:-moz-placeholder,
.pw-uniform.no-focus input[type="month"]:-moz-placeholder,
.pw-uniform.no-focus input[type="time"]:-moz-placeholder,
.pw-uniform.no-focus input[type="week"]:-moz-placeholder,
.pw-uniform.no-focus textarea:-moz-placeholder {
  font-weight: 100;
  color: #676767;
}
.pw-uniform.no-focus input[type="text"]:focus,
.pw-uniform.no-focus input[type="email"]:focus,
.pw-uniform.no-focus input[type="number"]:focus,
.pw-uniform.no-focus input[type="password"]:focus,
.pw-uniform.no-focus input[type="search"]:focus,
.pw-uniform.no-focus input[type="tel"]:focus,
.pw-uniform.no-focus input[type="url"]:focus,
.pw-uniform.no-focus input[type="color"]:focus,
.pw-uniform.no-focus input[type="date"]:focus,
.pw-uniform.no-focus input[type="datetime"]:focus,
.pw-uniform.no-focus input[type="datetime-local"]:focus,
.pw-uniform.no-focus input[type="month"]:focus,
.pw-uniform.no-focus input[type="time"]:focus,
.pw-uniform.no-focus input[type="week"]:focus,
.pw-uniform.no-focus textarea:focus {
  outline: none;
}
.pw-uniform.no-focus fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.pw-uniform.no-focus fieldset legend {
  border: none;
  border-bottom: 4px solid #ababab;
  color: #ababab;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  position: static;
  width: 100%;
}
.pw-uniform.no-focus fieldset fieldset legend {
  border-bottom: 2px solid #ababab;
  font-weight: normal;
}
.pw-uniform.no-focus fieldset fieldset fieldset legend {
  border-bottom: 1px solid #ababab;
  font-weight: normal;
  font-size: 13px;
}
.pw-uniform.no-focus [data-row-span] {
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  zoom: 1;
}
.pw-uniform.no-focus [data-row-span]:before,
.pw-uniform.no-focus [data-row-span]:after {
  content: "";
  display: table;
}
.pw-uniform.no-focus [data-row-span]:after {
  clear: both;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .pw-uniform.no-focus [data-row-span] {
    border-bottom: none;
  }
}
.pw-uniform.no-focus [data-row-span] [data-field-span] {
  padding: 8px;
  float: left;
}
@media only screen and (min-width: 0) and (max-width: 700px) {
  .pw-uniform.no-focus [data-row-span] [data-field-span] {
    border-bottom: 1px solid #d0d0d0;
    width: 100% !important;
  }
}
@media only screen and (min-width: 700px) {
  .pw-uniform.no-focus [data-row-span] [data-field-span] {
    border-right: 1px solid #d0d0d0;
    display: block;
  }
}
.pw-uniform.no-focus [data-row-span] [data-field-span] > label:first-of-type,
.pw-uniform.no-focus [data-row-span] [data-field-span] label.grid-form-label {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #414141;
  display: block;
  margin-bottom: 10px;
  padding-top: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pw-uniform.no-focus
  [data-row-span]
  [data-field-span]
  > label:first-of-type:hover,
.pw-uniform.no-focus
  [data-row-span]
  [data-field-span]
  label.grid-form-label:hover {
  cursor: text;
}
.pw-uniform.no-focus [data-row-span] [data-field-span]:last-child {
  border-right: none;
}
.pw-uniform.no-focus [data-row-span] [data-field-span].focus {
  background: transparent;
}
.pw-uniform.no-focus [data-row-span] [data-field-span]:hover {
  background: transparent;
  cursor: text;
}
.pw-uniform.no-focus [data-row-span="1"] > [data-field-span="1"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="2"] > [data-field-span="1"] {
  width: 50%;
}
.pw-uniform.no-focus [data-row-span="2"] > [data-field-span="2"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="3"] > [data-field-span="1"] {
  width: 33.33333%;
}
.pw-uniform.no-focus [data-row-span="3"] > [data-field-span="2"] {
  width: 66.66667%;
}
.pw-uniform.no-focus [data-row-span="3"] > [data-field-span="3"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="4"] > [data-field-span="1"] {
  width: 25%;
}
.pw-uniform.no-focus [data-row-span="4"] > [data-field-span="2"] {
  width: 50%;
}
.pw-uniform.no-focus [data-row-span="4"] > [data-field-span="3"] {
  width: 75%;
}
.pw-uniform.no-focus [data-row-span="4"] > [data-field-span="4"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="5"] > [data-field-span="1"] {
  width: 20%;
}
.pw-uniform.no-focus [data-row-span="5"] > [data-field-span="2"] {
  width: 40%;
}
.pw-uniform.no-focus [data-row-span="5"] > [data-field-span="3"] {
  width: 60%;
}
.pw-uniform.no-focus [data-row-span="5"] > [data-field-span="4"] {
  width: 80%;
}
.pw-uniform.no-focus [data-row-span="5"] > [data-field-span="5"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="6"] > [data-field-span="1"] {
  width: 16.66667%;
}
.pw-uniform.no-focus [data-row-span="6"] > [data-field-span="2"] {
  width: 33.33333%;
}
.pw-uniform.no-focus [data-row-span="6"] > [data-field-span="3"] {
  width: 50%;
}
.pw-uniform.no-focus [data-row-span="6"] > [data-field-span="4"] {
  width: 66.66667%;
}
.pw-uniform.no-focus [data-row-span="6"] > [data-field-span="5"] {
  width: 83.33333%;
}
.pw-uniform.no-focus [data-row-span="6"] > [data-field-span="6"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="7"] > [data-field-span="1"] {
  width: 14.28571%;
}
.pw-uniform.no-focus [data-row-span="7"] > [data-field-span="2"] {
  width: 28.57143%;
}
.pw-uniform.no-focus [data-row-span="7"] > [data-field-span="3"] {
  width: 42.85714%;
}
.pw-uniform.no-focus [data-row-span="7"] > [data-field-span="4"] {
  width: 57.14286%;
}
.pw-uniform.no-focus [data-row-span="7"] > [data-field-span="5"] {
  width: 71.42857%;
}
.pw-uniform.no-focus [data-row-span="7"] > [data-field-span="6"] {
  width: 85.71429%;
}
.pw-uniform.no-focus [data-row-span="7"] > [data-field-span="7"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="1"] {
  width: 12.5%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="2"] {
  width: 25%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="3"] {
  width: 37.5%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="4"] {
  width: 50%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="5"] {
  width: 62.5%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="6"] {
  width: 75%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="7"] {
  width: 87.5%;
}
.pw-uniform.no-focus [data-row-span="8"] > [data-field-span="8"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="1"] {
  width: 11.11111%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="2"] {
  width: 22.22222%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="3"] {
  width: 33.33333%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="4"] {
  width: 44.44444%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="5"] {
  width: 55.55556%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="6"] {
  width: 66.66667%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="7"] {
  width: 77.77778%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="8"] {
  width: 88.88889%;
}
.pw-uniform.no-focus [data-row-span="9"] > [data-field-span="9"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="1"] {
  width: 10%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="2"] {
  width: 20%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="3"] {
  width: 30%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="4"] {
  width: 40%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="5"] {
  width: 50%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="6"] {
  width: 60%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="7"] {
  width: 70%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="8"] {
  width: 80%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="9"] {
  width: 90%;
}
.pw-uniform.no-focus [data-row-span="10"] > [data-field-span="10"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="1"] {
  width: 9.09091%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="2"] {
  width: 18.18182%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="3"] {
  width: 27.27273%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="4"] {
  width: 36.36364%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="5"] {
  width: 45.45455%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="6"] {
  width: 54.54545%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="7"] {
  width: 63.63636%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="8"] {
  width: 72.72727%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="9"] {
  width: 81.81818%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="10"] {
  width: 90.90909%;
}
.pw-uniform.no-focus [data-row-span="11"] > [data-field-span="11"] {
  width: 100%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="1"] {
  width: 8.33333%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="2"] {
  width: 16.66667%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="3"] {
  width: 25%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="4"] {
  width: 33.33333%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="5"] {
  width: 41.66667%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="6"] {
  width: 50%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="7"] {
  width: 58.33333%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="8"] {
  width: 66.66667%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="9"] {
  width: 75%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="10"] {
  width: 83.33333%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="11"] {
  width: 91.66667%;
}
.pw-uniform.no-focus [data-row-span="12"] > [data-field-span="12"] {
  width: 100%;
}
.pw-uniform .combined-errors .pw-error-message-container {
  display: none;
}
.pw-uniform .combined-errors .pw-error-message-container:last-of-type {
  display: block;
}
.pw-uniform ::-webkit-input-placeholder {
  color: #a6a6a6 !important;
}
.pw-uniform :-moz-placeholder {
  /* Firefox 18- */
  color: #a6a6a6 !important;
}
.pw-uniform ::-moz-placeholder {
  /* Firefox 19+ */
  color: #a6a6a6 !important;
  opacity: 1;
}
.pw-uniform :-ms-input-placeholder {
  color: #a6a6a6 !important;
}
.pw-uniform li {
  font-size: 16px;
}
.pw-uniform li > label {
  display: block;
}
.pw-uniform label {
  padding-top: 0px;
  font-weight: normal;
}
.pw-uniform input.fake-input {
  border: 1px solid #d2d2d2 !important;
  background: #fff !important;
}
.pw-uniform .select {
  display: inline-block;
  width: initial;
}
.pw-uniform textarea {
  box-shadow: none;
  resize: vertical;
}
.pw-uniform input,
.pw-uniform textarea {
  color: #000;
}
.pw-uniform .inline-option-with-input {
  vertical-align: middle;
}
.pw-uniform .inline-option-with-input label {
  display: inline-block;
  vertical-align: middle;
}
.pw-uniform .inline-option-with-input input[type="text"] {
  display: inline-block;
  border: 1px solid #d0d0d0;
  padding: 5px;
  border-radius: 4px;
}
.pw-uniform .pw-uniform-table {
  margin-bottom: 10px;
}
.pw-uniform .pw-uniform-table.no-border [data-row-span]:only-child {
  border-bottom: none;
}
.pw-uniform [data-row-span].no-border {
  border-bottom: none;
}
.pw-uniform [data-row-span].full-border {
  border: 1px solid #d0d0d0;
}
.pw-uniform [data-field-span] {
  position: relative;
}
.pw-uniform [data-field-span].not-text:hover {
  cursor: default;
}
.pw-uniform [data-field-span].not-text:hover label:hover {
  cursor: default;
}
.pw-uniform [data-field-span].radio-buttons:hover,
.pw-uniform [data-field-span].radio-buttons.focus {
  background: none;
}
.pw-uniform [data-field-span].radio-buttons li {
  margin: 5px 0;
  padding: 5px 8px;
  line-height: 30px;
}
.pw-uniform [data-field-span].radio-buttons li.selected,
.pw-uniform [data-field-span].radio-buttons li:hover {
  background: #f9fbff;
}
.pw-uniform [data-field-span].radio-buttons li > label {
  margin: 0;
}
.pw-uniform [data-field-span].show-background {
  background: #f9fbff;
}
.pw-uniform.white-focus [data-field-span].show-background {
  background: #fff;
}
.pw-uniform.no-focus [data-field-span].show-background {
  background: transparent;
}
.pw-uniform [data-field-span].uneditable.focus,
.pw-uniform [data-field-span].uneditable:hover,
.pw-uniform [data-field-span].no-focus.focus,
.pw-uniform [data-field-span].no-focus:hover {
  background: initial !important;
}
@media screen and (max-width: 600px) {
  .pw-uniform div.pw-flash-message {
    width: 100%;
  }
}
.pw-uniform .form-group {
  margin: 0;
}
.pw-uniform .form-heading .form-title {
  margin-bottom: 4px;
  border: none;
}
.pw-uniform .form-heading .form-description {
  margin-bottom: 8px;
}
.pw-uniform span.pw-help-text-redactor p:last-child {
  margin-bottom: 4px;
}
.pw-uniform .pw-error-message {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  font-style: italic;
  font-size: 0.9em;
  color: #aa3336;
  margin-right: 5px;
  box-sizing: border-box;
  border-radius: 2px;
}
.pw-uniform .pw-error-message:after {
  content: ",";
}
.pw-uniform .pw-error-message:last-child:after {
  content: none;
}
.pw-uniform .redactor-box {
  background: none;
}
.pw-uniform .redactor-box ::selection {
  background: #accfff;
}
.pw-uniform .redactor-box textarea {
  font-size: 1em;
  background-color: #222;
  color: #fff;
}
.pw-uniform.white-focus .redactor-editor {
  background: #fff;
  padding: 15px;
}
.pw-uniform .redactor-toolbar {
  background: none;
  box-shadow: none;
  border-bottom: 1px solid #000;
}
.pw-uniform .redactor-toolbar.toolbar-fixed-box {
  background: #fff;
}
.pw-uniform .redactor-toolbar.toolbar-fixed-box.visible-toolbar {
  visibility: visible !important;
}
.pw-uniform .redactor-toolbar.redactor-toolbar-overflow {
  height: auto;
  white-space: pre-wrap;
}
.pw-uniform .redactor-toolbar > li {
  height: 100%;
  font-size: 14px;
}
.pw-uniform .redactor-toolbar > li a.redactor-btn-image {
  width: auto;
  width: initial;
  height: auto;
  height: initial;
}
.pw-uniform .redactor-form-control > p:last-child {
  margin-bottom: 0 !important;
}
.pw-uniform .pw-image-picker {
  background: none;
  border: none;
}
.pw-uniform .button-submit-container {
  padding-top: 20px;
  text-align: center;
}

div.pw-flash-message {
  position: relative;
  margin-bottom: 10px;
  padding-right: 30px;
  box-sizing: border-box;
  border-radius: 2px;
}
div.pw-flash-message.alert a.pwai-x {
  color: #a94442;
}
div.pw-flash-message a.pwai-x {
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}
