.discussion-list-item-view:first-of-type {
  border-top: none;
}

.discussion-list-item-view.discussion-question .discussion-item-wrap {
  padding: 25px 30px;
}

.discussion-list-item-view.discussion-question .discussion-who {
  display: none;
}

.discussion-list-item-view.discussion-question .discussion-detail {
  margin-left: 0;
}

.discussion-list-item-view .discussion-list-item-wrapper {
  padding: 15px;
}
.discussion-list-item-view .discussion-list-item-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.discussion-list-item-view .discussion-list-item-wrapper .discussion-detail {
  margin: 0 0 0 50px;
}
.discussion-list-item-view .discussion-list-item-wrapper .discussion-icon {
  float: left;
}
.discussion-list-item-view .discussion-list-item-wrapper .discussion-meta span {
  font-size: 13px;
}
.discussion-list-item-view .discussion-list-item-wrapper .discussion-title {
  padding-right: 30px;
}
.discussion-list-item-view .discussion-list-item-wrapper .discussion-title a {
  font-size: 17px;
}
.discussion-list-item-view
  .discussion-list-item-wrapper
  .discussion-body
  a
  > i {
  font-size: 0.7em;
  line-height: 1em;
}
.discussion-list-item-view .discussion-list-item-wrapper .discussion-action {
  position: static;
}
.discussion-list-item-view .discussion-list-item-wrapper .discussion-meta span,
.discussion-list-item-view
  .discussion-list-item-wrapper
  .discussion-action
  span {
  margin-right: 0;
}
.discussion-list-item-view
  .discussion-list-item-wrapper
  .discussion-meta
  span:not(:last-of-type):after,
.discussion-list-item-view
  .discussion-list-item-wrapper
  .discussion-action
  span:not(:last-of-type):after {
  content: "|";
  margin-left: 3px;
}
.discussion-list-item-view
  .discussion-list-item-wrapper
  .discussion-admin-menu
  > a {
  color: #999;
}

@media screen and (max-width: 480px) {
  .discussion-list-item-view .discussion-list-item-wrapper .discussion-detail {
    margin-left: 30px;
  }
  .discussion-list-item-view
    .discussion-list-item-wrapper
    .discussion-icon
    pw-avatar
    a,
  .discussion-list-item-view
    .discussion-list-item-wrapper
    .discussion-icon
    pw-avatar
    a
    img {
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .discussion-list-item-view .discussion-list-item-wrapper .icon-a {
    margin-left: 0;
  }
}

.discussion-question-list-view a.paginate i {
  position: relative;
  top: 2px;
}

.discussion-question-list-view a.paginate:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.discussion-detail-view .discussion-meta span {
  margin-right: 0;
}
.discussion-detail-view .discussion-meta span:after {
  content: "|";
  margin-right: 2px;
  margin-left: 5px;
}
.discussion-detail-view .discussion-meta span:last-of-type:after {
  display: none;
}
