.InviteForm {
  min-height: 400px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 10px 10px;
}
.InviteForm .ais-InstantSearch__root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.InviteForm header {
  flex-shrink: 0;
  padding: 15px 50px 0;
}
.InviteForm form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 400px;
}
.InviteForm .ScrollView {
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0 50px 10px;
}
.InviteForm .BlankSlate h4 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
}
.InviteForm .Alert {
  width: 100%;
  border: none;
  max-width: none;
  margin: 0;
  padding: 5px 50px;
  border-radius: 0;
  background-color: #fae5e8;
  color: #d0011b;
}

.InviteForm__prompt {
  display: flex;
  align-items: center;
}

.InviteForm__prompt a {
  text-decoration: underline;
}

.InviteForm__prompt .Link {
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  .InviteForm header,
  .InviteForm .Alert,
  .InviteForm .ScrollView {
    padding-left: 20px;
    padding-right: 20px;
  }

  .InviteForm__prompt {
    display: block;
  }
}
