.Lesson {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Lesson:not(.Lesson--static) {
  cursor: pointer;
}

html.no-touch .Lesson:not(.Lesson--static):hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.Lesson__link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Lesson__cover {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 60px;
  height: 75px;
  padding: 10px;
  border-radius: 4px 0 0 4px;
}

.Lesson__cover img {
  width: 100%;
}

.Lesson__info {
  flex-grow: 1;
  position: relative;
  padding: 10px;
  padding-left: 0;
  width: 50%;
  overflow: hidden;
}

.Lesson__name {
  overflow: hidden;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  max-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  font-family: -apple-system, ".SFNSText-Bold", "San Francisco",
    "ProximaNova-Bold", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .Lesson__name {
    font-size: 14px;
    line-height: 16px;
    max-height: 32px;
  }
}

.Lesson__meta {
  margin: 0;
  white-space: nowrap;
  text-overflow: clip;
  font-size: 14px;
  line-height: 20px;
  color: #888;
}

.Lesson__price {
  margin: 0;
  font-size: 14px;
}
