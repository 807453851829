.Col {
  position: relative;
  min-height: 1px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.Col--xs-0 {
  width: 0;
}

.Col--xs-1 {
  width: 8.333333333333332%;
}

.Col--xs-2 {
  width: 16.666666666666664%;
}

.Col--xs-3 {
  width: 25%;
}

.Col--xs-4 {
  width: 33.33333333333333%;
}

.Col--xs-5 {
  width: 41.66666666666667%;
}

.Col--xs-6 {
  width: 50%;
}

.Col--xs-7 {
  width: 58.333333333333336%;
}

.Col--xs-8 {
  width: 66.66666666666666%;
}

.Col--xs-9 {
  width: 75%;
}

.Col--xs-10 {
  width: 83.33333333333334%;
}

.Col--xs-11 {
  width: 91.66666666666666%;
}

.Col--xs-12 {
  width: 100%;
}

@media (min-width: 480px) {
  .Col--sm-0 {
    width: 0;
  }
  .Col--sm-1 {
    width: 8.333333333333332%;
  }
  .Col--sm-2 {
    width: 16.666666666666664%;
  }
  .Col--sm-3 {
    width: 25%;
  }
  .Col--sm-4 {
    width: 33.33333333333333%;
  }
  .Col--sm-5 {
    width: 41.66666666666667%;
  }
  .Col--sm-6 {
    width: 50%;
  }
  .Col--sm-7 {
    width: 58.333333333333336%;
  }
  .Col--sm-8 {
    width: 66.66666666666666%;
  }
  .Col--sm-9 {
    width: 75%;
  }
  .Col--sm-10 {
    width: 83.33333333333334%;
  }
  .Col--sm-11 {
    width: 91.66666666666666%;
  }
  .Col--sm-12 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .Col--md-0 {
    width: 0;
  }
  .Col--md-1 {
    width: 8.333333333333332%;
  }
  .Col--md-2 {
    width: 16.666666666666664%;
  }
  .Col--md-3 {
    width: 25%;
  }
  .Col--md-4 {
    width: 33.33333333333333%;
  }
  .Col--md-5 {
    width: 41.66666666666667%;
  }
  .Col--md-6 {
    width: 50%;
  }
  .Col--md-7 {
    width: 58.333333333333336%;
  }
  .Col--md-8 {
    width: 66.66666666666666%;
  }
  .Col--md-9 {
    width: 75%;
  }
  .Col--md-10 {
    width: 83.33333333333334%;
  }
  .Col--md-11 {
    width: 91.66666666666666%;
  }
  .Col--md-12 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .Col--lg-0 {
    width: 0;
  }
  .Col--lg-1 {
    width: 8.333333333333332%;
  }
  .Col--lg-2 {
    width: 16.666666666666664%;
  }
  .Col--lg-3 {
    width: 25%;
  }
  .Col--lg-4 {
    width: 33.33333333333333%;
  }
  .Col--lg-5 {
    width: 41.66666666666667%;
  }
  .Col--lg-6 {
    width: 50%;
  }
  .Col--lg-7 {
    width: 58.333333333333336%;
  }
  .Col--lg-8 {
    width: 66.66666666666666%;
  }
  .Col--lg-9 {
    width: 75%;
  }
  .Col--lg-10 {
    width: 83.33333333333334%;
  }
  .Col--lg-11 {
    width: 91.66666666666666%;
  }
  .Col--lg-12 {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .Col--xl-0 {
    width: 0;
  }
  .Col--xl-1 {
    width: 8.333333333333332%;
  }
  .Col--xl-2 {
    width: 16.666666666666664%;
  }
  .Col--xl-3 {
    width: 25%;
  }
  .Col--xl-4 {
    width: 33.33333333333333%;
  }
  .Col--xl-5 {
    width: 41.66666666666667%;
  }
  .Col--xl-6 {
    width: 50%;
  }
  .Col--xl-7 {
    width: 58.333333333333336%;
  }
  .Col--xl-8 {
    width: 66.66666666666666%;
  }
  .Col--xl-9 {
    width: 75%;
  }
  .Col--xl-10 {
    width: 83.33333333333334%;
  }
  .Col--xl-11 {
    width: 91.66666666666666%;
  }
  .Col--xl-12 {
    width: 100%;
  }
}
