.progress-green {
  color: #3aba75;
}

.discussion-detail-view {
  z-index: 100;
}
.discussion-detail-view > header {
  padding: 20px 20px;
  padding-bottom: 0px;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  position: relative;
  display: none;
}
.discussion-detail-view > header .discussion-meta .discussion-context {
  text-transform: capitalize;
}
.discussion-detail-view > header .discussion-who {
  top: 28px;
}
.discussion-detail-view > header .discussion-detail {
  position: relative;
  padding-bottom: 20px;
}
.discussion-detail-view .discussion-title {
  font-size: 30px;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: normal;
}
@media screen and (max-width: 500px) {
  .discussion-detail-view .discussion-title {
    font-size: 21px;
  }
}
.discussion-detail-view .discussion-body {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 24px;
}

.discussion-detail-view .discussion-actions a {
  margin-right: 10px;
}
.discussion-detail-view .discussion-actions a i {
  position: relative;
  top: 1px;
}
.discussion-detail-view a {
  color: #828282;
}
.discussion-detail-view .new-responses-prompt {
  margin: 0px 20px;
}
.discussion-detail-view .discussion-response-region:not(:empty) {
  padding: 20px;
}

/* Begin Quill styling, TODO: unify */
.discussion-detail-view .discussion-body > p:empty,
.discussion-detail-view .discussion-body > p > br:only-child {
  display: none;
}

.discussion-detail-view .discussion-body .ql-align-center {
  text-align: center;
}

.discussion-detail-view .discussion-body .ql-align-left {
  text-align: left;
}

.discussion-detail-view .discussion-body .ql-align-right {
  text-align: right;
}

.discussion-detail-view .discussion-body .ql-align-justify {
  text-align: justify;
}

.discussion-detail-view .discussion-body ol,
.discussion-detail-view .discussion-body ul {
  list-style: none;
}

.discussion-detail-view .discussion-body ol,
.discussion-detail-view .discussion-body ul,
.discussion-detail-view .discussion-body ol li,
.discussion-detail-view .discussion-body ul li {
  padding-left: 1.5em;
}

/* list style */
.discussion-detail-view .discussion-body ol li {
  counter-increment: list-0;
}

.discussion-detail-view .discussion-body ol li:before {
  content: counter(list-0, decimal) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-1 {
  counter-increment: list-1;
}

.discussion-detail-view .discussion-body ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-2 {
  counter-increment: list-2;
}

.discussion-detail-view .discussion-body ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-3 {
  counter-increment: list-3;
}

.discussion-detail-view .discussion-body ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-4 {
  counter-increment: list-4;
}

.discussion-detail-view .discussion-body ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-5 {
  counter-increment: list-5;
}

.discussion-detail-view .discussion-body ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-6 {
  counter-increment: list-6;
}

.discussion-detail-view .discussion-body ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-7 {
  counter-increment: list-7;
}

.discussion-detail-view .discussion-body ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.discussion-detail-view .discussion-body ol li.ql-indent-8 {
  counter-increment: list-8;
}

.discussion-detail-view .discussion-body ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.discussion-detail-view .discussion-body ul li:before {
  content: "\2022";
}

/* list margin */
.discussion-detail-view .discussion-body .ql-indent-1 {
  margin-left: calc(1 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-2 {
  margin-left: calc(2 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-3 {
  margin-left: calc(3 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-4 {
  margin-left: calc(4 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-5 {
  margin-left: calc(5 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-6 {
  margin-left: calc(6 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-7 {
  margin-left: calc(7 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-8 {
  margin-left: calc(8 * 2em);
}

.discussion-detail-view .discussion-body .ql-indent-9 {
  margin-left: calc(9 * 2em);
}

.discussion-detail-view .discussion-body ul li:not(.ql-direction-rtl),
.discussion-detail-view .discussion-body ol li:not(.ql-direction-rtl) {
  padding-left: 0;
}

/* why? this causes ql-syntax to be forced into 1 line */
/* .discussion-detail-view .discussion-body pre {
  white-space: normal;
} */

.discussion-detail-view .discussion-body img {
  max-width: 100%;
}

.discussion-detail-view .discussion-body code,
.discussion-detail-view .discussion-body pre {
  font-family: "Operator Mono", "SFMono-Regular", Consolas, "Liberation Mono",
    Menlo, Courier, monospace;
  tab-size: 2;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 5px;
}

.discussion-detail-view .discussion-body a,
.discussion-detail-view .discussion-body p a,
.discussion-detail-view .discussion-body li a {
  text-decoration: underline;
}

.discussion-detail-view .discussion-body .ql-blank::before {
  left: 0;
  right: 0;
}

.discussion-detail-view .discussion-body pre.ql-syntax,
.discussion-detail-view .discussion-body pre.ql-syntax {
  /* bg color pulled from .BlockText */
  background-color: #f6f8fa;
  color: inherit;
}

.discussion-detail-view .discussion-body .katex {
  /* line-height: 1.8;
  font-size: 90%; */
  white-space: pre-wrap !important;
}
/* End Quill styling */
