.DockPanelHeader {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: white;
}

.DockPanelHeader .IconButton {
  flex-shrink: 0;
}

.DockPanelHeader .IconButton + .IconButton:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 1px;
  height: 30px;
  background-color: #ddd;
}

.DockPanelHeader .IconButton .Pathicon:before {
  transition: transform 500ms ease-in-out;
}

.DockPanel--docked .DockPanelHeader__orient,
.DockPanel--docked .DockPanelHeader__close {
  visibility: hidden;
}

.DockPanelHeader__content {
  overflow: hidden;
  flex-grow: 1;
  text-align: center;
  /* padding-left: 50px;
  padding-right: 100px; */
}

.DockPanelHeader__content > h4,
.DockPanelHeader__content > h4 > a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 0; /* hack to account for clipped text */
}

.DockPanelHeader__dock + .DockPanelHeader__content {
  /* padding-left: 100px; */
}
