.ResourceTheme__background {
  position: relative;
  height: 150px;
}
.ResourceTheme__background .Button {
  position: absolute;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.ResourceThemeForm label {
  padding: 0 !important;
}
.ResourceThemeForm .ColorPickerToggle__button > div {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
