.DatePicker th,
.DatePicker td.rdtTimeToggle {
  font-family: -apple-system, ".SFNSText-Bold", "San Francisco",
    "ProximaNova-Bold", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 700;
}

.DatePicker .rdtPicker button,
.DatePicker th.rdtSwitch,
.DatePicker td.rdtTimeToggle {
  color: #428bca;
}

.DatePicker .FieldStyler__input {
  flex-grow: 0;
}

.DatePicker .DatePicker__input {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DatePicker__input .rdt {
  position: relative;
}

.DatePicker__input .rdt input {
  display: block;
  height: auto;
  padding: 5px 30px;
  font-size: 15px;
  line-height: 25px;
  background-color: transparent;
  color: black;
  cursor: auto;
}

.DatePicker .IconButton__calendar {
  position: absolute;
  left: 0;
  pointer-events: none;
}

.DatePicker .IconButton__x {
  position: absolute;
  right: 0;
}
