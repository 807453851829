.CheckboxGroup .FieldStyler--inline {
  display: inline-block;
}

.CheckboxGroup .FieldStyler__input input,
.CheckboxGroup .FieldStyler__input textarea {
  padding: 2px 5px;
}

.CheckboxGroup .TextInput,
.CheckboxGroup .TextInput > .FieldStyler__field {
  position: static;
}

.Checkbox {
  display: flex;
  margin: 0;
  padding: 2px 0;
  line-height: 25px;
  align-items: center;
}

.Checkbox > * + * {
  margin-left: 0.5em;
}

.Checkbox input[type="checkbox"] {
  width: 25px;
  flex-shrink: 0;
}

.Checkbox .checkbox_label {
  display: none;
}
