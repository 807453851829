.progress-green {
  color: #3aba75;
}

.SchoolLink {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  min-width: 1px;
}
.SchoolLink:last-of-type .SchoolLink__wrapper {
  border-bottom: none;
}

.SchoolLink__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.SchoolLink__img {
  width: 30px;
  margin-right: 15px;
}

.SchoolLink__wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px dotted #aaa;
}

.SchoolLink__label {
  flex-grow: 1;
}
.SchoolLink__label h4 {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
  color: #222;
}
.SchoolLink__label span {
  text-transform: capitalize;
  color: #888;
}

.SchoolLink__arrow {
  color: #888;
}
