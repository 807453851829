.Gates {
  padding: 20px;
  text-align: center;
  color: #444;
  background: #eee;
  align-items: center;
  flex-grow: 1;
}

.Gates__wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.Gates__wrapper .GateButton {
  width: 100%;
  max-width: none;
}

.Gates__selected {
  padding: 20px;
}

.Gates__selected > button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
