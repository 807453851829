.Modal.Modal--cardstack .Modal__close {
  display: none;
}

@media screen and (max-width: 480px) {
  .Modal:not(.User__Modal):not(.ResourceTrailer__Modal):not(
      .LibraryPicker__Modal
    )
    > .ScrollView {
    background-color: #fff;
  }
  .Modal:not(.User__Modal):not(.ResourceTrailer__Modal):not(
      .LibraryPicker__Modal
    )
    .Modal__close {
    color: #565656;
  }
  .Modal:not(.User__Modal):not(.ResourceTrailer__Modal):not(
      .LibraryPicker__Modal
    )
    .Modal__content {
    height: 100%;
    padding: 0;
  }
}
