.ManageLicenseForm .RichText .InputProvider__label_optional {
  display: none;
}

.ManageLicenseForm__inline-number {
  display: flex;
  align-items: baseline;
  padding: 10px 25px 0;
}
.ManageLicenseForm__inline-number input {
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
}
