.Dropdown__Menu {
  z-index: 9001;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.Dropdown__Menu.Dropdown__Menu__Divot {
  margin-top: 10px;
  overflow: visible;
}

.Dropdown__Menu.Dropdown__Menu__Divot:before {
  content: "";
  z-index: 0;
  position: absolute;
  top: -7px;
  right: 18px;
  width: 14px;
  height: 14px;
  background-color: white;
  box-shadow: -1px -1px 0 #ddd;
  border-top-left-radius: 3px;
  transform: rotate(45deg);
}
