.progress-green {
  color: #3aba75;
}

.DockPanel {
  position: absolute; /* position fixed not working on iOS (issue is with nesting a position fixed element inside an overflow hidden element?) */
  background-color: #f5f5f5;
  right: 0;
  bottom: 0;
  z-index: 100000010;
  overflow: hidden;
  padding-top: 50px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
}

.DockPanel__header {
  position: absolute;
  width: 100%;
  top: 0;
  height: 50px;
}

.DockPanel__content {
  width: 100%;
  height: 100%;
}

.DockPanel__content--scroll {
  overflow: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
}

.DockPanel__undock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  display: none;
}

.DockPanel__underlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  z-index: 100000000;
  cursor: pointer;
}

.DockPanel__resize {
  z-index: 1;
  position: absolute;
  left: -5px;
  top: 0;
  height: 100%;
  width: 10px;
  cursor: col-resize;
}

.DockPanel--open {
  min-width: min(320px, 100vw);
}

.DockPanel--docked {
  cursor: pointer;
  opacity: 0.7;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
}

.DockPanel--docked .DockPanel__content {
  pointer-events: none;
}

.DockPanel--docked .DockPanel__undock {
  display: block;
}

.DockPanel--bottom {
  bottom: 0;
  border-top: 1px solid #d2cfcf;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}
