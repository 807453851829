.TextArea textarea {
  color: #000;
  width: 100%;
  resize: vertical;
  resize: none;
  min-height: 1.5em;
}

.TextArea.style--code textarea {
  font-size: 13px;
  font-family: Menlo, Monaco, monospace, sans-serif !important;
}

.TextArea__input {
  position: relative;
}
