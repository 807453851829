.Invitations {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
}
.Invitations .ScrollView {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 10px 50px;
  height: 400px;
}
@media screen and (max-width: 480px) {
  .Invitations .ScrollView,
  .Invitations .Invitations__controls {
    padding: 10px 20px;
  }
}

.Invitations__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Invitations__controls {
  flex-shrink: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px 50px 5px;
}
.Invitations__controls h4 {
  margin: 0;
}
.Invitations__controls .Button,
.Invitations__controls .Dropdown__Item {
  text-transform: capitalize;
}

.Invitations__more,
.Invitations__end {
  position: relative;
  height: 40px;
  text-align: center;
  color: #888;
}
