/*
NOTE: Chakra has a resetCSS option that automatically inserts these styles globaly.
This does not work well for us since we have a large, pre-existing codebase whose styles
can be mangled by this CSS reset. Instead, we import this CSS file manually at the top
(rather than allowing the styles to be injected dynamically when the ChakraProvider mounts
which gives these styles even greater preference coming last). We also scope the majority of
these styles to a .chakra-ui-reset-css class which must wrap any Chakra UIs wishing to take
advantage of the CSS reset.
*/

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

body {
  position: relative;
  min-height: 100%;
  font-feature-settings: "kern";
}

*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
}

/* NOTE: Using :where() to avoid increasing the specificity of the selectors. */

:where(.chakra-ui-reset-css, .chakra-portal) main {
  display: block;
}

:where(.chakra-ui-reset-css, .chakra-portal) hr {
  border-top-width: 1px;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

:where(.chakra-ui-reset-css, .chakra-portal) pre,
:where(.chakra-ui-reset-css, .chakra-portal) code,
:where(.chakra-ui-reset-css, .chakra-portal) kbd,
:where(.chakra-ui-reset-css, .chakra-portal) samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  font-size: 1em;
}

:where(.chakra-ui-reset-css, .chakra-portal) a {
  background-color: transparent;
  color: inherit;
  text-decoration: inherit;
}

:where(.chakra-ui-reset-css, .chakra-portal) abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(.chakra-ui-reset-css, .chakra-portal) b,
:where(.chakra-ui-reset-css, .chakra-portal) strong {
  font-weight: bold;
}

:where(.chakra-ui-reset-css, .chakra-portal) small {
  font-size: 80%;
}

:where(.chakra-ui-reset-css, .chakra-portal) sub,
:where(.chakra-ui-reset-css, .chakra-portal) sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

:where(.chakra-ui-reset-css, .chakra-portal) sub {
  bottom: -0.25em;
}

:where(.chakra-ui-reset-css, .chakra-portal) sup {
  top: -0.5em;
}

:where(.chakra-ui-reset-css, .chakra-portal) img {
  border-style: none;
}

:where(.chakra-ui-reset-css, .chakra-portal) button,
:where(.chakra-ui-reset-css, .chakra-portal) input,
:where(.chakra-ui-reset-css, .chakra-portal) optgroup,
:where(.chakra-ui-reset-css, .chakra-portal) select,
:where(.chakra-ui-reset-css, .chakra-portal) textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

:where(.chakra-ui-reset-css, .chakra-portal) button,
:where(.chakra-ui-reset-css, .chakra-portal) input {
  overflow: visible;
}

:where(.chakra-ui-reset-css, .chakra-portal) button,
:where(.chakra-ui-reset-css, .chakra-portal) select {
  text-transform: none;
}

:where(.chakra-ui-reset-css, .chakra-portal) button::-moz-focus-inner,
:where(.chakra-ui-reset-css, .chakra-portal) [type="button"]::-moz-focus-inner,
:where(.chakra-ui-reset-css, .chakra-portal) [type="reset"]::-moz-focus-inner,
:where(.chakra-ui-reset-css, .chakra-portal) [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:where(.chakra-ui-reset-css, .chakra-portal) fieldset {
  padding: 0.35em 0.75em 0.625em;
}

:where(.chakra-ui-reset-css, .chakra-portal) legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

:where(.chakra-ui-reset-css, .chakra-portal) progress {
  vertical-align: baseline;
}

:where(.chakra-ui-reset-css, .chakra-portal) textarea {
  overflow: auto;
}

:where(.chakra-ui-reset-css, .chakra-portal) [type="checkbox"],
:where(.chakra-ui-reset-css, .chakra-portal) [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:where(.chakra-ui-reset-css, .chakra-portal)
  [type="number"]::-webkit-inner-spin-button,
:where(.chakra-ui-reset-css, .chakra-portal)
  [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

:where(.chakra-ui-reset-css, .chakra-portal) input[type="number"] {
  -moz-appearance: textfield;
}

:where(.chakra-ui-reset-css, .chakra-portal) [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

:where(.chakra-ui-reset-css, .chakra-portal)
  [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none !important;
}

:where(.chakra-ui-reset-css, .chakra-portal) ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(.chakra-ui-reset-css, .chakra-portal) details {
  display: block;
}

:where(.chakra-ui-reset-css, .chakra-portal) summary {
  display: list-item;
}

:where(.chakra-ui-reset-css, .chakra-portal) template {
  display: none;
}

:where(.chakra-ui-reset-css, .chakra-portal) [hidden] {
  display: none !important;
}

:where(.chakra-ui-reset-css, .chakra-portal) body,
:where(.chakra-ui-reset-css, .chakra-portal) blockquote,
:where(.chakra-ui-reset-css, .chakra-portal) dl,
:where(.chakra-ui-reset-css, .chakra-portal) dd,
:where(.chakra-ui-reset-css, .chakra-portal) h1,
:where(.chakra-ui-reset-css, .chakra-portal) h2,
:where(.chakra-ui-reset-css, .chakra-portal) h3,
:where(.chakra-ui-reset-css, .chakra-portal) h4,
:where(.chakra-ui-reset-css, .chakra-portal) h5,
:where(.chakra-ui-reset-css, .chakra-portal) h6,
:where(.chakra-ui-reset-css, .chakra-portal) hr,
:where(.chakra-ui-reset-css, .chakra-portal) figure,
:where(.chakra-ui-reset-css, .chakra-portal) p,
:where(.chakra-ui-reset-css, .chakra-portal) pre {
  margin: 0;
}

:where(.chakra-ui-reset-css, .chakra-portal) button {
  background: transparent;
  padding: 0;
}

:where(.chakra-ui-reset-css, .chakra-portal) fieldset {
  margin: 0;
  padding: 0;
}

:where(.chakra-ui-reset-css, .chakra-portal) ol,
:where(.chakra-ui-reset-css, .chakra-portal) ul {
  margin: 0;
  padding: 0;
}

:where(.chakra-ui-reset-css, .chakra-portal) textarea {
  resize: vertical;
}

:where(.chakra-ui-reset-css, .chakra-portal) button,
:where(.chakra-ui-reset-css, .chakra-portal) [role="button"] {
  cursor: pointer;
}

:where(.chakra-ui-reset-css, .chakra-portal) button::-moz-focus-inner {
  border: 0 !important;
}

:where(.chakra-ui-reset-css, .chakra-portal) table {
  border-collapse: collapse;
}

:where(.chakra-ui-reset-css, .chakra-portal) h1,
:where(.chakra-ui-reset-css, .chakra-portal) h2,
:where(.chakra-ui-reset-css, .chakra-portal) h3,
:where(.chakra-ui-reset-css, .chakra-portal) h4,
:where(.chakra-ui-reset-css, .chakra-portal) h5,
:where(.chakra-ui-reset-css, .chakra-portal) h6 {
  font-size: inherit;
  font-weight: inherit;
}

:where(.chakra-ui-reset-css, .chakra-portal) button,
:where(.chakra-ui-reset-css, .chakra-portal) input,
:where(.chakra-ui-reset-css, .chakra-portal) optgroup,
:where(.chakra-ui-reset-css, .chakra-portal) select,
:where(.chakra-ui-reset-css, .chakra-portal) textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

:where(.chakra-ui-reset-css, .chakra-portal) img,
:where(.chakra-ui-reset-css, .chakra-portal) svg,
:where(.chakra-ui-reset-css, .chakra-portal) video,
:where(.chakra-ui-reset-css, .chakra-portal) canvas,
:where(.chakra-ui-reset-css, .chakra-portal) audio,
:where(.chakra-ui-reset-css, .chakra-portal) iframe,
:where(.chakra-ui-reset-css, .chakra-portal) embed,
:where(.chakra-ui-reset-css, .chakra-portal) object {
  display: block;
}

:where(.chakra-ui-reset-css, .chakra-portal) img,
:where(.chakra-ui-reset-css, .chakra-portal) video {
  max-width: 100%;
  height: auto;
}

:where(.chakra-ui-reset-css, .chakra-portal)
  [data-js-focus-visible]
  :focus:not([data-focus-visible-added]):not([data-focus-visible-disabled]) {
  outline: none;
  box-shadow: none;
}

:where(.chakra-ui-reset-css, .chakra-portal) select::-ms-expand {
  display: none;
}

/* TODO: remove this once we find a fix for Chakra toast layering */
.chakra-portal > ul {
  z-index: 99999999999999 !important;
}

/* .chakra-portal:has(.chakra-toast),
.chakra-portal:has(> .chakra-popover__popper):not(
    :has(.MentorGroupStaffListItemAvatarCardTrigger)
  ) {
  position: relative;
  z-index: 99999999999999 !important;
}

.chakra-portal:has(.MentorGroupStaffListItemAvatarCardTrigger) {
  position: relative;
  z-index: 1000002019 !important;
} */

/* Allow for chakra portals to appear on top of most everything... */
.chakra-portal:has(> .chakra-popover__popper),
.chakra-portal:has(> .chakra-modal__overlay) {
  position: fixed;
  z-index: 1000002020 !important;
}
/* Ensure toast is slightly higher than popover and modal */
.chakra-portal:has(.chakra-toast) {
  position: fixed;
  z-index: 1000002030 !important;
}

/* END TODO */

:root {
  --chakra-vh: 100vh;
}

@supports (height: -webkit-fill-available) {
  :root {
    --chakra-vh: -webkit-fill-available;
  }
}

@supports (height: -moz-fill-available) {
  :root {
    --chakra-vh: -moz-fill-available;
  }
}

@supports (height: 100lvh) {
  :root {
    --chakra-vh: 100lvh;
  }
}
