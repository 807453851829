.AuthorForm__form {
  display: flex;
  padding-left: 25px;
}
@media screen and (max-width: 480px) {
  .AuthorForm__form {
    flex-direction: column;
    padding-left: 0;
  }
}
.AuthorForm__form .ImageUpload {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 10px auto;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.AuthorForm__form .ImageUpload .CoverPreviewer {
  padding-top: 100%;
}
.AuthorForm__form .ImageUpload,
.AuthorForm__form .CoverUploader,
.AuthorForm__form .CoverPreviewer,
.AuthorForm__form .CoverUploader label,
.AuthorForm__form .CoverPreviewer label {
  border-radius: 50px;
}
.AuthorForm__form .AuthorForm__fields {
  flex-grow: 1;
}
