.NavbarUser {
  height: 100%;
  display: flex;
  align-items: stretch;
}
.NavbarUser > * {
  height: 100%;
}
.NavbarUser .Button {
  height: 100%;
}
.NavbarUser .Button + .Button:before,
.NavbarUser .Button + .Dropdown__Trigger:before {
  content: "";
  position: absolute;
  bottom: 15px;
  top: 15px;
  left: 0;
  height: 20px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.NavbarUser__user {
  display: flex;
}
.NavbarUser__user span {
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .NavbarUser__user span {
    display: none;
  }
}

.NavbarUser__signin {
  height: 100%;
}

.NavbarUser__signin .Button__content-wrapper {
  height: 100%;
}

.NavbarUser__dropdown {
  display: flex;
  align-items: stretch;
}
.NavbarUser__dropdown .Avatar {
  margin-right: 5px;
}
.NavbarUser__dropdown .Dropdown__Menu {
  border-radius: 0 0 10px 10px;
  min-width: 150px;
}
