.Invitation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 100px);
  text-align: left;
}
.Invitation .Avatar {
  margin-right: 5px;
}

@media screen and (max-width: 480px) {
  .Invitation {
    flex-direction: column;
  }
  .Invitation .Invitation__description {
    text-align: center;
    padding-top: 10px;
  }
}
