.CardHeader {
  position: relative;
}

.CardHeader__wrapper {
  position: relative;
  color: white;
  text-align: center;
}

.CardHeader__padding {
  padding-left: 30px;
  padding-right: 30px;
}

.CardHeader__title {
  margin: 0;
  font-weight: 700;
}

.CardHeader__meta {
  margin: 0;
}

.CardHeader__meta span + span:before {
  content: "•";
  margin: 0 3px;
}

.CardHeader .Link {
  color: white;
  border-bottom: 1px solid white;
}
