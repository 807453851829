@font-face {
  font-family: "ProximaNova-Regular";
  src: url("./ProximaNova/ProximaNova-Regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-Italic";
  src: url("./ProximaNova/ProximaNova-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("./ProximaNova/ProximaNova-Bold.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NimbusSans-Regular";
  src: url("./NimbusSans/NimbusSans-Regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NimbusSans-Italic";
  src: url("./NimbusSans/NimbusSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NimbusSans-Bold";
  src: url("./NimbusSans/NimbusSans-Bold.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./Montserrat/Montserrat-Regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url("./Montserrat/Montserrat-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./Montserrat/Montserrat-Bold.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Newsreader-Regular";
  src: url("./Newsreader/Newsreader-Regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Newsreader-Italic";
  src: url("./Newsreader/Newsreader-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Newsreader-Bold";
  src: url("./Newsreader/Newsreader-Bold.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EBGaramond-Regular";
  src: url("./EBGaramond/EBGaramond-Regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EBGaramond-Italic";
  src: url("./EBGaramond/EBGaramond-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EBGaramond-Bold";
  src: url("./EBGaramond/EBGaramond-Bold.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SeaweedScript-Regular";
  src: url("./SeaweedScript/SeaweedScript-Regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "VujahdayScript-Regular";
  src: url("./VujahdayScript/VujahdayScript-Regular.woff");
  font-weight: normal;
  font-style: normal;
}
