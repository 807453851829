.progress-green {
  color: #3aba75;
}

.discussion-form-view {
  min-height: 100%;
}
.discussion-form-view > h1 {
  text-align: center;
  font-size: 2em;
  margin: 0px;
  margin-bottom: 20px;
}
.discussion-form-view .discussion-detail-inputs {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  max-width: 650px;
  margin: 0px auto;
  margin-top: 15px;
}
.discussion-form-view .discussion-detail-inputs > div:last-of-type {
  border-bottom: none;
}
.discussion-form-view .question-type-options li {
  list-style: none;
  padding: 10px;
  min-height: 50px;
}
.discussion-form-view .question-type-options li label {
  font-weight: normal;
  margin: 0px;
  font-size: 1em;
  position: relative;
  color: #828282;
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
}

.discussion-form-view .radio-buttons .question-type-options li label {
  grid-template-columns: min-content 50px 1fr;
  padding-left: 20px;
}

.discussion-form-view .question-type-options li label strong {
  font-weight: normal;
  color: #000;
}
.discussion-form-view .question-type-options li label pw-avatar {
  padding: 8px;
  margin: 0 auto;
}
.discussion-form-view .question-type-options li label span {
  display: inline-block;
}
.discussion-form-view footer {
  color: #828282;
  padding: 20px 10px;
  text-align: center;
}
.discussion-form-view .discussion-tip {
  margin-top: 10px;
}

.discussion-detail-view .discussion-form-view {
  min-height: 0px;
  border-bottom: 1px solid #d4d4d4;
}
