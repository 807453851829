.InboxFocusCompletionItem {
  container-name: inbox-focus-completion-item;
  container-type: inline-size;
}

@container inbox-focus-completion-item (max-width: 768px) {
  .InboxFocusCompletionItem > main > .meta {
    flex-direction: column;
    align-items: flex-start;
  }

  .InboxFocusCompletionItem > main > .meta .meta-reviewers {
    margin-top: 0.4em;
    padding-left: 0;
  }

  .InboxFocusCompletionItem > main > .meta .meta-reviewers:before {
    display: none;
  }
}
