.InviteFormError {
  font-size: 12px;
  padding: 5px 50px;
  color: #d0011b;
  background-color: #fae5e8;
}
@media screen and (max-width: 480px) {
  .InviteFormError {
    padding: 5px 20px;
  }
}
