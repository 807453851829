.RadioGroup--old .FieldWrapper--inline {
  display: inline-block;
}

.RadioGroup--old .FieldWrapper__input input,
.RadioGroup--old .FieldWrapper__input textarea {
  padding: 2px 5px;
  min-height: 25px;
}

.RadioGroup--old .FieldWrapper__input input {
  height: 26px;
}

.RadioGroup--old .TextInput,
.RadioGroup--old .TextInput > .FieldWrapper__field {
  position: static;
}

.RadioGroup--old .DatePicker .FieldWrapper__input {
  flex-grow: 0;
}

.RadioGroup--old .RadioGroup__description {
  margin: -5px 0 5px;
  color: #666;
}

.RadioButton--old {
  display: flex;
  margin: 0;
  padding: 5px 0;
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  align-items: center;
}
.RadioButton--old > span {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
}
.RadioButton--old input[type="radio"] + div:last-child {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.RadioButton--old input[type="radio"] {
  flex-shrink: 0;
  border: none;
  outline: none;
  margin: 0;
  margin-right: 8px;
  align-self: flex-start;
}
.RadioButton--old input[type="number"] {
  display: inline;
}
