.progress-green {
  color: #3aba75;
}

html.splash-screen .browse-layout-view {
  overflow-y: hidden;
}

.loading-splash {
  color: #fff;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #333;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://pathwright.imgix.net/https%3A%2F%2Fcdn.filestackcontent.com%2Fapi%2Ffile%2FGadYV8vROCKJthjP0lgg%3Fpolicy%3DeyJjYWxsIjogWyJyZWFkIiwgInN0YXQiLCAiY29udmVydCJdLCAiZXhwaXJ5IjogNDYyMDM3NzAzMX0%253D%26signature%3D888b9ea3eb997a4d59215bfbe2983c636df3c7da0ff8c6f85811ff74c8982e34?balph=40&blend=666666&blur=500&bm=screen&fit=max&fm=jpg&h=500&high=-80&ixlib=python-1.1.0&q=100&w=500&s=482076a08970530ae4105068ccb15c0a");
}
.loading-splash .loading-splash-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.loading-splash .loading-splash-info {
  position: relative;
  top: 20%;
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loading-splash .loading-splash-info img {
  max-width: 200px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.loading-splash.school-logo img {
  max-width: 70px;
}
.loading-splash .loading-splash-spinner {
  position: absolute;
  width: 100%;
  bottom: 20%;
}
.loading-splash .spinner {
  border: 2px solid rgba(255, 255, 255, 0.9);
  border-right-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  -ms-animation: spin 1s linear infinite;
  -o-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

img.background-image {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
