.InviteLookupResults {
  z-index: 10;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: -1px;
  overflow: hidden;
  background-color: white;
  border: 1px solid #6a9fcb;
  border-radius: 0 0 4px 4px;
  border-top: none;
}
.InviteLookupResults:empty {
  display: none;
}

.InviteLookupResults__existing:not(:first-child) {
  margin-top: 20px;
}

.InviteLookupResults__heading {
  overflow: hidden;
  height: 1.1em;
  margin: 0 0 5px;
  padding: 0 10px;
  font-size: 15px;
  color: #888;
}
.InviteLookupResults__heading span {
  display: inline-block;
  white-space: nowrap;
}

.InviteLookupResults__multiple {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #aaa;
  cursor: pointer;
}
.InviteLookupResults__multiple h4 {
  margin: 0;
  flex-grow: 1;
  font-size: 16px;
}
