@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-moz-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loading-container {
  min-height: 200px;
  height: 100%;
  width: 100%;
  position: relative;
}
.loading-container .message {
  padding: 10px 0 10px 0;
}
.loading-container .message h3 {
  margin: 0;
  text-align: center;
}
.loading-container.path-skeleton {
  padding-left: 30px;
}
@media (max-width: 600px) {
  .loading-container.path-skeleton {
    padding-left: 10px;
  }
}
.loading-container.path-skeleton .step {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  background-color: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  margin-bottom: 30px;
}
.loading-container.path-skeleton .bar {
  background: #e3e4e8;
  height: 10px;
  width: 80%;
  margin: 25px 0 0 25px;
  background: #f6f7f8 no-repeat 800px 104px;
}
.loading-container.path-skeleton .dot {
  position: absolute;
  bottom: 20px;
  background: #f6f7f8;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.loading-container.path-skeleton .dot-1 {
  left: 25px;
}
.loading-container.path-skeleton .dot-2 {
  left: 40px;
}
.loading-container.path-skeleton .dot-3 {
  left: 55px;
}
.loading-container.path-skeleton .dot-4 {
  left: 70px;
}
.loading-container.path-skeleton .dot-5 {
  left: 85px;
}
