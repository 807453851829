.discussion-response-delete-view {
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  min-height: 100%;
  color: #fff;
  z-index: 1000;
  top: 0px;
  left: 0px;
  text-align: center;
}
.discussion-response-delete-view h1 {
  margin: 0px;
  color: #fff;
  font-size: 1.4em;
}
.discussion-response-delete-view p {
  display: inline;
}
.discussion-response-delete-view .archive-action {
  margin-top: 5px;
}
.discussion-response-delete-view .cancel-delete-response,
.discussion-response-delete-view .cancel-delete-response:hover {
  text-decoration: underline;
  color: #fff;
}

.discussion-detail-view > .discussion-response-delete-view {
  padding-top: 72px;
}
