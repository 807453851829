.progress-green {
  color: #3aba75;
}

#content-container .content a,
#content-container .content a:hover,
#content-container .content a:focus {
  text-decoration: underline;
}

.Text a,
.content-reset a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.Text ul,
.content-reset ul {
  list-style-type: disc;
}

.Text ol,
.content-reset ol {
  list-style-type: decimal;
}

.Text ol > li > ol,
.content-reset ol > li > ol {
  list-style-type: lower-alpha;
}

.Text ol > li > ol > li > ol,
.content-reset ol > li > ol > li > ol {
  list-style-type: lower-roman;
}

.Text ol,
.Text ul,
.content-reset ol,
.content-reset ul {
  padding-left: 1.5em;
  margin: 0px;
  margin-bottom: 15px;
}

.Text ol + ol,
.Text ol + ul,
.Text ul + ol,
.Text ul + ul,
.content-reset ol + ol,
.content-reset ol + ul,
.content-reset ul + ol,
.content-reset ul + ul {
  margi-top: -15px;
}

.Text blockquote,
.content-reset blockquote {
  font-style: italic;
  margin: 0 0 20px;
  padding: 9px 20px 0 19px;
  border-left: 1px solid #ddd;
}

.Text code,
.Text pre,
.content-reset code,
.content-reset pre {
  font-family: Menlo, Monaco, monospace, sans-serif;
}

.Text table,
.content-reset table {
  border-collapse: collapse;
  font-size: 1em;
  width: 100%;
}

.Text table td,
.Text table th,
.content-reset table td,
.content-reset table th {
  padding: 5px;
  border: 1px solid #ddd;
  vertical-align: top;
}

.Text table thead td,
.Text table th,
.content-reset table thead td,
.content-reset table th {
  font-weight: bold;
  border-bottom-color: #888;
}

.Text--theme h1,
.theme-typography h1,
.Redactor--theme .redactor-editor h1 {
  font-family: "ProximaNova-Light", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #000;
  font-size: 42px;
  line-height: 52px;
}

.Text--theme h2,
.theme-typography h2,
.Redactor--theme .redactor-editor h2 {
  font-family: "ProximaNova-Bold", "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000;
  font-size: 38px;
  line-height: 48px;
}

.Text--theme h3,
.theme-typography h3,
.Redactor--theme .redactor-editor h3 {
  font-family: "ProximaNova-Regular", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  font-size: 32px;
  line-height: 42px;
}

.Text--theme h4,
.theme-typography h4,
.Redactor--theme .redactor-editor h4 {
  font-family: "ProximaNova-Bold", "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000;
  font-size: 26px;
  line-height: 36px;
}

.Text--theme h5,
.theme-typography h5,
.Redactor--theme .redactor-editor h5 {
  font-family: "ProximaNova-Bold", "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000;
  font-size: 22px;
  line-height: 32px;
}

.Text--theme blockquote,
.theme-typography blockquote,
.Redactor--theme .redactor-editor blockquote {
  font-family: "ProximaNova-LightIt", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-style: italic;
  color: #000;
  font-size: 26px;
  line-height: 42px;
}

.Text--theme div,
.Text--theme p,
.Text--theme li,
.theme-typography div,
.theme-typography p,
.theme-typography li,
.Redactor--theme .redactor-editor div,
.Redactor--theme .redactor-editor p,
.Redactor--theme .redactor-editor li {
  font-family: "FreightText-Book", "Georgia", serif;
  font-weight: 400;
  font-style: normal;
  color: #444;
  font-size: 21px;
  line-height: 33px;
}

.Text--theme p,
.theme-typography p,
.Redactor--theme .redactor-editor p {
  margin: 0 0 28px;
}

.Text--theme span:not([class]),
.theme-typography span:not([class]),
.Redactor--theme .redactor-editor span:not([class]) {
  font-family: "FreightText-Book", "Georgia", serif;
  font-weight: 400;
  font-style: normal;
  color: #444;
  font-size: 22px;
  line-height: 36px;
}

.Text--theme i,
.Text--theme em,
.theme-typography i,
.theme-typography em,
.Redactor--theme .redactor-editor i,
.Redactor--theme .redactor-editor em {
  font-family: "FreightText-Book", "Georgia", serif;
  font-weight: 400;
  font-style: italic;
}

.Text--theme b,
.Text--theme b *,
.Text--theme b span:not([class]),
.Text--theme strong,
.Text--theme strong *,
.Text--theme strong span:not([class]),
.theme-typography b,
.theme-typography b *,
.theme-typography b span:not([class]),
.theme-typography strong,
.theme-typography strong *,
.theme-typography strong span:not([class]),
.Redactor--theme .redactor-editor b,
.Redactor--theme .redactor-editor b *,
.Redactor--theme .redactor-editor b span:not([class]),
.Redactor--theme .redactor-editor strong,
.Redactor--theme .redactor-editor strong *,
.Redactor--theme .redactor-editor strong span:not([class]) {
  font-family: "FreightText-Bold", "Georgia", serif;
  font-weight: 700;
  font-style: normal;
}

.Text--theme i > b,
.Text--theme em > b,
.Text--theme b > i,
.Text--theme b > em,
.Text--theme strong > i,
.Text--theme strong > em,
.Text--theme i > strong,
.Text--theme em > strong,
.theme-typography i > b,
.theme-typography em > b,
.theme-typography b > i,
.theme-typography b > em,
.theme-typography strong > i,
.theme-typography strong > em,
.theme-typography i > strong,
.theme-typography em > strong,
.Redactor--theme .redactor-editor i > b,
.Redactor--theme .redactor-editor em > b,
.Redactor--theme .redactor-editor b > i,
.Redactor--theme .redactor-editor b > em,
.Redactor--theme .redactor-editor strong > i,
.Redactor--theme .redactor-editor strong > em,
.Redactor--theme .redactor-editor i > strong,
.Redactor--theme .redactor-editor em > strong {
  font-family: "FreightText-BoldIt", "Georgia", serif;
  font-weight: 700;
  font-style: italic;
}

.Text--quill > p:empty {
  display: none;
}

.Text--quill .ql-align-center {
  text-align: center;
}

.Text--quill .ql-align-left {
  text-align: left;
}

.Text--quill .ql-align-right {
  text-align: right;
}

.Text--quill .ql-align-justify {
  text-align: justify;
}

.Text--quill ol,
.Text--quill ul {
  list-style: none;
}

.Text--quill ol,
.Text--quill ul {
  padding-left: 0;
}

.Text--quill ol li,
.Text--quill ul li {
  padding-left: 1.5em;
  list-style-type: none;
}

/* list style */
.Text--quill ol li {
  counter-increment: list-0;
}

.Text--quill ol li:before,
.Text--quill ul li:before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}

.Text--quill ol li:before {
  content: counter(list-0, decimal) ". ";
}

.Text--quill ol li.ql-indent-1 {
  counter-increment: list-1;
}

.Text--quill ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.Text--quill ol li.ql-indent-2 {
  counter-increment: list-2;
}

.Text--quill ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.Text--quill ol li.ql-indent-3 {
  counter-increment: list-3;
}

.Text--quill ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.Text--quill ol li.ql-indent-4 {
  counter-increment: list-4;
}

.Text--quill ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.Text--quill ol li.ql-indent-5 {
  counter-increment: list-5;
}

.Text--quill ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.Text--quill ol li.ql-indent-6 {
  counter-increment: list-6;
}

.Text--quill ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.Text--quill ol li.ql-indent-7 {
  counter-increment: list-7;
}

.Text--quill ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.Text--quill ol li.ql-indent-8 {
  counter-increment: list-8;
}

.Text--quill ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.Text--quill ul li:before {
  content: "\2022";
}

/* list margin */
.Text--quill .ql-indent-1 {
  margin-left: calc(1 * 1.5em);
}

.Text--quill .ql-indent-2 {
  margin-left: calc(2 * 1.5em);
}

.Text--quill .ql-indent-3 {
  margin-left: calc(3 * 1.5em);
}

.Text--quill .ql-indent-4 {
  margin-left: calc(4 * 1.5em);
}

.Text--quill .ql-indent-5 {
  margin-left: calc(5 * 1.5em);
}

.Text--quill .ql-indent-6 {
  margin-left: calc(6 * 1.5em);
}

.Text--quill .ql-indent-7 {
  margin-left: calc(7 * 1.5em);
}

.Text--quill .ql-indent-8 {
  margin-left: calc(8 * 1.5em);
}

.Text--quill .ql-indent-9 {
  margin-left: calc(9 * 1.5em);
}

.Text--quill img {
  max-width: 100%;
}

.Text--quill code,
.Text--quill pre {
  font-family: "Operator Mono", "SFMono-Regular", Consolas, "Liberation Mono",
    Menlo, Courier, monospace;
  tab-size: 2;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 5px;
}

.Text--quill a,
.Text--quill p a,
.Text--quill li a {
  text-decoration: underline;
}

.Text--quill .ql-blank::before {
  left: 0;
  right: 0;
}

.Text--quill pre.ql-syntax,
.Text--quill pre.ql-syntax {
  /* bg color pulled from .BlockText */
  background-color: #f6f8fa;
  color: inherit;
}

.Text--quill .katex {
  white-space: pre-wrap !important;
}
