.GlobalAuth {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.GlobalAuth .SignedIn a {
  display: none;
}

.GlobalAuth__header {
  text-align: center;
  padding: 20px;
  padding-top: 30px;
}
.GlobalAuth__header img {
  max-width: 60px;
}
.GlobalAuth__header h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 700;
  font-size: 24px;
}

.GlobalAuth__wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 25px;
  margin: 0 auto;
  max-width: 550px;
}
@media screen and (max-width: 480px) {
  .GlobalAuth__wrapper {
    padding: 5px;
  }
}

.GlobalSchoolList {
  min-height: 75px;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 0 10px;
}
