.CheckboxGroup .FieldWrapper--inline {
  display: inline-block;
}

.CheckboxGroup .FieldWrapper__input input,
.CheckboxGroup .FieldWrapper__input textarea {
  padding: 2px 5px;
}

.CheckboxGroup .TextInput,
.CheckboxGroup .TextInput > .FieldWrapper__field {
  position: static;
}

.Checkbox {
  display: flex;
  margin: 0;
  padding: 2px 0;
  font-size: 16px;
  line-height: 25px;
  align-items: baseline;
}

.Checkbox input[type="checkbox"] {
  width: 25px;
  flex-shrink: 0;
}
