.Avatar {
  position: relative;
  display: inline-block;
  line-height: 0;
  flex-shrink: 0;
  user-select: none;
  text-transform: uppercase;
  font-family: -apple-system, ".SFNSText-SemiBold", "San Francisco",
    "ProximaNova-Bold", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 600;
  border-radius: 100%;
}

.Avatar > *:not(.Link) {
  pointer-events: none;
}

.Avatar .Link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Avatar .Avatar--initials {
  color: white;
  background-color: #333;
  text-align: center;
}

.Avatar:not(.Avatar--with-img) .Link > div {
  color: white;
}

.Avatar .Avatar--img,
.Avatar .Avatar--initials {
  position: relative;
  overflow: hidden;
  border-radius: 100%;
}
