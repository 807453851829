.BackgroundImage,
.BackgroundImage__image,
.BackgroundImage__overlay,
.BackgroundImage__empty {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.FullScreen > .BackgroundImage .BackgroundImage__image,
.FullScreen > .BackgroundImage .BackgroundImage__overlay,
.FullScreen > .BackgroundImage .BackgroundImage__empty {
  backface-visibility: hidden;
  z-index: -1;
  will-change: transform;
}

.BackgroundImage__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
