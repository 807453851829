.TextInput input {
  width: 100%;
}
.TextInput input[placeholder] {
  text-overflow: ellipsis;
}

.TextInput__input {
  position: relative;
}

.TextInput--autosize input {
  min-height: auto !important;
}

.TextInput--autosize .FieldWrapper__input {
  flex-grow: 0;
}

.TextInput--autosize .FieldWrapper__status {
  overflow: visible;
  white-space: nowrap;
}
