.CountIndicator {
  border-radius: 100%;
  font-weight: 600;
  text-align: center;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.CountIndicator--empty {
  font-size: 0;
}
