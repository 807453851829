.AvatarCardTrigger {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.AvatarCardTrigger .Overlay {
  z-index: 9999999999999;
}

.AvatarCardTrigger .AvatarCard {
  min-width: 15em;
}
