.InfiniteGrid .Grid__cell {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.InfiniteGrid .Grid__cell .BlankSlate {
  margin: 0;
}

.ReactVirtualized__Grid:focus,
.ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}

.ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer {
  position: static !important; /* allow for dropdown menu visibility  */
}
