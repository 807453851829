/* LOCAL: Boostrap Overrides */
.progress-green {
  color: #3aba75;
}

#filepicker_dialog_container,
#filepicker_shade {
  z-index: 1000000000000 !important;
}

.modal.fade {
  top: 0;
}

.container {
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.navbar-search .search-query {
  font-family: "ProximaNova-Regular", Arial, Helvetica, Geneva, sans-serif;
}

.alert {
  padding: 8px;
  border-radius: 2px;
}

.alert-error {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-error hr {
  border-top-color: #e4b9c0;
}

.alert-error .alert-link {
  color: #843534;
}

hr {
  border-top: 1px solid #dedede;
}

.btn:focus,
.btn-primary:focus {
  outline: none;
}

.btn-link {
  color: #0d71fb;
}

.popover {
  z-index: 999999999999999 !important;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none !important;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  -webkit-appearance: none;
  box-shadow: none;
  transition: none;
}

textarea {
  padding: 6px 12px;
}

select,
input[type="file"] {
  height: auto;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */
  *margin-top: 4px;
  /* For IE7, add top margin to align select with labels */
  line-height: auto;
}

h1,
h2,
h3 {
  font-size: auto;
  line-height: 1em;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  outline: 0;
  box-shadow: none;
  transition: none;
}

*:focus,
.form-control:focus {
  outline: 0 !important;
  box-shadow: none;
}

ol,
ul {
  margin: 0px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

form {
  margin: 0px;
}

select,
select:focus {
  box-shadow: none;
  transition: none;
}

input,
button,
select,
textarea {
  font-family: "ProximaNova-Regular", Arial, Helvetica, Geneva, sans-serif;
}

abbr[title],
abbr[data-original-title] {
  cursor: default;
  border-bottom: none;
}
