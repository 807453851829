.ManageResourceDetails_richtext {
  padding-left: 0;
  padding-right: 0;
}
.ManageResourceDetails_richtext .FieldWrapper__label {
  font-size: 1em;
  margin-bottom: 4px;
}

.ManageResourceDetails .ToggleInput .UIFieldWrapper__input {
  position: absolute;
  top: 6px;
  right: 0;
}

.ManageResourceDetails_RedactorRichText .redactor-box textarea {
  font-size: 1em;
  background-color: #222;
  color: #fff;
}
