.AskToAnswerAction {
  position: relative;
}
.AskToAnswerAction:not(.AskToAnswerAction--selected):hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.AskToAnswerAction--selected a {
  opacity: 0.5;
}

.AskToAnswerAction--selected:after {
  content: "\e61c";
  font-family: "pathicons";
  line-height: 10px;
  font-size: 10px;
  position: absolute;
  top: 8px;
  left: 40px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #6a9fcb;
  transform: translateY(-50%);
  color: white;
}
