.ProgressCircle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.ProgressCircle__progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ProgressCircle__inner {
  position: relative;
}
