.CounterInput input {
  width: 100%;
  text-align: center;
}

.CounterInput input[placeholder] {
  text-overflow: ellipsis;
}

.CounterInput__wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

/* Edge fix where <style> appearing as child of .CounterInput__wrapper and displays in UI */
.CounterInput__wrapper style {
  display: none !important;
}

.CounterInput--autosize input {
  min-height: auto !important;
}

.CounterInput--autosize .FieldStyler__input {
  flex-grow: 0;
}

.CounterInput--autosize .FieldStyler__status {
  overflow: visible;
  white-space: nowrap;
}
