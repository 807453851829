.Invite {
  padding-top: 0;
  padding-bottom: 0;
  max-height: calc(100vh - 100px);
}
.Invite .ActivationLockedFallback {
  margin: 0 auto;
  max-width: 400px;
  padding: 50px 20px 100px;
}
.Invite .ActivationLockedFallback .Emoji {
  font-size: 24px;
}

.Invite__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Invite__Modal .Modal__content > div,
.Invite__Modal > .Modal > .ScrollView {
  background-color: transparent;
}

.Invite__Modal .Modal__close {
  display: none;
}
